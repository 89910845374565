import fetch from "isomorphic-fetch";
import history from "../history";
import { message } from "antd";

export function callApi(endpoint, method = "get", body) {
  //let headers = {'content-type' :'application/json'}
  let headers = { "content-type": "application/json", Authorization: "" };
  if (localStorage.getItem("access_token")) {
    headers.Authorization = `Bearer ${localStorage.getItem("access_token")}`;
  } else if (sessionStorage.getItem("access_token")) {
    headers.Authorization = `Bearer ${sessionStorage.getItem("access_token")}`;
  }

  const apiURL = process.env.REACT_APP_API_URL;
  //const apiURL = 'https://api.observation.ai'
  //const apiURL = 'http://localhost:4001'
  return fetch(apiURL + endpoint, {
    headers: headers,
    method,
    body: JSON.stringify(body),
  }).then(async(response) => {
    if (!response.ok) {
      let errorMessage = "";
      const jsonResponse = await response.json()
      if (jsonResponse.message) {
        errorMessage = jsonResponse.message;
        console.error("server error " + response.status + " message: " + errorMessage);
        if (response.status === 400 || response.status === 500){
          message.error(errorMessage);
        }
      } else{
        console.error('server error ' + JSON.stringify(jsonResponse));
      }

      if (response.status === 401) {
        message.error("Not logged in");
        //appState.reset();
        localStorage.removeItem("access_token");
        localStorage.removeItem("userName");
        localStorage.removeItem("userAdmin");
        localStorage.removeItem("customerID");

        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("userAdmin");
        sessionStorage.removeItem("customerID");

        history.push("/");
      } else if (response.status === 403) {
        message.error("You aren't authorized");
      }

      return Promise.reject(response);
    }
    if (response.status === 204) {
      return;
    }
    //if 200 success
    return response.json();
  });
}
