import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { inject } from "mobx-react";
import { callApi } from "../libs/api";
import { LockOutlined } from "@ant-design/icons";
import isValidPassword from "./isValidPassword";
import PasswordStrength from "./PasswordStrength";

const ChangePassword = (props) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);

  const changePassword = () => {
    if (!isValidPassword(password, passwordConfirm)) return;

    setLoading(true);
    const url = "/api/users/changePassword/";
    const body = { password };
    callApi(url, "PUT", body)
      .then((data) => {
        message.info("Password changed.");
        setLoading(false);
      })
      .catch((err) => {
        console.error(
          "Error: callApi() -> url: " + url + " error: " + JSON.stringify(err)
        );
        message.error("Change Password Error.");
        setLoading(false);
      });
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmation = (event) => {
    setPasswordConfirm(event.target.value);
  };

  return (
    <div className="mybg" style={{paddingTop: 100}}>
      
        <div className="borderedContainer">
          <h3>Change Password</h3>
          <div>
            New Password{" "}
            <Input.Password
              value={password}
              onChange={handlePassword}
              placeholder="Password"
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              size="large"
            />
          </div>
          <div>
            Confirm Password{" "}
            <Input.Password
              value={passwordConfirm}
              onChange={handlePasswordConfirmation}
              placeholder="Password Confirmation"
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              size="large"
            />
          </div>
          <PasswordStrength password={password} confirmPassword={passwordConfirm} />
          <div style={{ marginTop: 10 }}>
            <Button
              style={{ width: "100%" }}
              loading={loading}
              type="primary"
              onClick={changePassword}
              size="large"
            >
              Change Password
            </Button>
            
          </div>
        
      </div>
    </div>
  );
};

export default inject("appState")(ChangePassword);
