import React, { useState, useEffect } from "react";
import { callApi } from "../libs/api";
import { Button, Table, message } from "antd";
import moment from "moment";
//import GDeleteButton from "../Comp/GDeleteButton";
import UserModal from "./modal";
import { PlusCircleOutlined } from "@ant-design/icons";
import { observer, inject } from 'mobx-react';


const Users = (props) => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  const columns = [
    {
      title: "email/user name",
      dataIndex: "user_name",
      key: "user_name",
    },
    {
      title: "Role",
      dataIndex: "role",
      key: "role",
      render: (text, record) => (
        <span>{record.admin ? "Admin" : "Labeler"}</span>
      ),
    },
    {
      title: "Active Login",
      dataIndex: "active",
      key: "active",
      render: (text, record) => (
        <span>{record.active ? "Active" : "Deactivated"}</span>
      ),
    },
    {
      title: "last login",
      dataIndex: "lastLogin",
      key: "lastLogin",
      render: (text, record) => (
        <span>{text && new moment(text).format("MM/DD/YYYY HH:mm")}</span>
      ),
    },
    // {
    //   title: 'Edit',
    //   key: 'edit',
    //   render: (text, record) =>
    //     <Button >Edit</Button>

    {
      title: "Deactivate",
      key: "deactivate",
      render: (text, record) => (
        <span>
          {record.user_name !== props.appState.userName && (
            <span>
              {record.active ? (
                <Button type="danger" onClick={() => changeActive(record)}>
                  Disable Login
                </Button>
              ) : (
                <Button type="primary" onClick={() => changeActive(record)}>
                  Enable Login
                </Button>
              )}
            </span>
          )}
        </span>
      ),
    },
  ];

  const changeActive = (record) => {
    setLoading(true);
    const url = "/api/users/changeUserActivation";
    const body = { userID: record._id, active: !record.active };
    callApi(url, "PUT", body)
      .then((data) => {
        setLoading(false);
        loadData();
      })
      .catch((err) => {
        setLoading(false);
        setError(true)
        console.error(
          "Error: callApi() -> url: " + url + " error: " + JSON.stringify(err)
        );
      });
  };

  useEffect(() => {
    //fetch users
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    const url = "/api/users/";
    callApi(url, "get")
      .then((data) => {
        setLoading(false);
        setUsers(data);
      })
      .catch((err) => {
        message.error('Error getting users')
        setLoading(false);
        console.error(
          "Error: callApi() -> url: " + url + " error: " + JSON.stringify(err)
        );
      });
  };

  const handleNewUser = () => {
    //setCameraID(0);
    setShowDialog(true);
  };

  // const updateUser = (id) => {
  //   //setCameraID(id);
  //   setShowDialog(true);
  // };

  const close = () => {
    loadData();
    setShowDialog(false);
  };


  return (
    <div style={{ margin: 30 }}>
      <Button
        type="primary"
        style={{ marginBottom: 7, float: 'right'}}
        onClick={handleNewUser}
      > 
        <PlusCircleOutlined />
        Add Teammate{" "}
      </Button>
      <h3>My Team</h3>
      {error && "Error Loading"}

      <Table
        loading={loading}
        rowKey="_id"
        dataSource={users}
        columns={columns}
      />

      <UserModal showDialog={showDialog} close={close}  />
    </div>
  );
};

export default inject("appState")(observer(Users));
