import React, { useEffect, useState } from "react";
import { inject, observer } from "mobx-react";
import { Modal, Spin, Alert, Tag, Button } from "antd";
import moment from "moment";
import getTagColor from "./getTagColor";
import { callApi } from "../libs/api";

const ImageHistory = (props) => {
  const [image, setImage] = useState({});
  const [imageLoading, setImageLoading] = useState(false);
  const [imageError, setImageError] = useState(false);

  useEffect(() => {
    if (props.show) {
      getImageById(props.id);
    }
  }, [props.show]);

  const getImageById = (imageId) => {
    if (imageId === undefined) return;
    setImageLoading(true);
    setImageError(false);
    setImage({});
    const url = "/api/image/queryHistory/" + imageId;
    callApi(url, "GET")
      .then((data) => {
        setImageLoading(false);
        setImage(data);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setImageLoading(false);
        setImageError(true);
      });
  };

  //const image = props.appState.imageList[props.appState.imageIdx] || {};

  return (
    <div>
      <Modal
        title="Image Details"
        visible={props.show}
        onOk={props.close}
        onCancel={props.close}
        footer={[
          <Button key="ok" type="primary" onClick={props.close}>
            OK
          </Button>,
        ]}
      >
        <div>
          <span style={{ fontWeight: "bold" }}>Image ID:</span> {image._id}
        </div>

        {imageLoading && (
          <div>
            <Spin size="large" />
          </div>
        )}
        {imageError && <Alert message="Error loading Image" type="error" />}

        {image.lastUpdateUser && (
          <div>
            {!image.history && (
              <div>
                <span style={{ fontWeight: "bold" }}>Last tagged by:</span>{" "}
                {image.lastUpdateUser}
                <div>
                  <span style={{ fontWeight: "bold" }}>Last tagged time:</span>{" "}
                  {new moment(image.lastUpdate).format("MM/DD/YYYY HH:mm:ss")}
                </div>
              </div>
            )}

            {image.history &&
              image.history.map((hist, idxCnt) => {
                return (
                  <div style={{ marginTop: 12 }} key={idxCnt}>
                    <div>
                      <span style={{ fontWeight: "bold" }}>User:</span>{" "}
                      {hist.user}
                    </div>
                    <div>
                      <span style={{ fontWeight: "bold" }}>Change time:</span>{" "}
                      {new moment(hist.changeTime).format(
                        "MM/DD/YYYY HH:mm:ss"
                      )}
                    </div>
                    <div>
                      {hist.description} 
                      {hist.tags && 
                        hist.tags.map((tag, idx) => (
                          <Tag
                            key={idx}
                            color={getTagColor(tag)}
                            style={{ marginLeft: 5 }}
                          >
                            {tag}
                          </Tag>
                        ))}
                    </div>
                  </div>
                );
              })}
          </div>
        )}
      </Modal>
    </div>
  );
};
export default inject("appState")(observer(ImageHistory));
