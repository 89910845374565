import React, { useState, useEffect } from "react";
import { Button, Input, message } from "antd";
import { inject } from "mobx-react";
import { callApi } from "../libs/api";
import { LoginOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";
import isValidPassword from "./isValidPassword";
import isValidEmail from "./isValidEmail";
import PasswordStrength from "./PasswordStrength";

const Signup = (props) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (props.appState.loggedIn) {
      history.push("/image");
    }
  }, []);

  const signup = () => {
    //check for valid email
    if (!isValidEmail(userName)) {
      message.error("Invalid email address");
      return;
    }

    if (!isValidPassword(password, confirmPassword)) return;

    setLoading(true);
    const url = "/open/login/signup/";
    const body = { userName, password };
    callApi(url, "POST", body)
      .then((data) => {
        sessionStorage.setItem("access_token", data.token);
        sessionStorage.setItem("userName", userName);
        sessionStorage.setItem("userAdmin", data.profile.admin);

        props.appState.userName = userName;
        props.appState.loggedIn = true;
        props.appState.setUserAdmin(data.profile.admin);
        history.push("/camera");
        //history.push("/");
      })
      .catch((err) => {
        setLoading(false);
      });
  };

  const handleUserName = (event) => {
    setUserName(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  return (
    <div className="mybg">
      <div className="signupContainer">
        <h2>Sign up</h2>
        <div>
          Email{" "}
          <Input
            autoFocus
            value={userName}
            onChange={handleUserName}
            placeholder="Email"
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            size="large"
          />
        </div>
        <div style={{ marginTop: 6 }}>
          Password{" "}
          <Input.Password
            value={password}
            onChange={handlePassword}
            placeholder="Password"
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            size="large"
          />
        </div>

        <div style={{ marginTop: 6 }}>
          Confirm Password{" "}
          <Input.Password
            value={confirmPassword}
            onChange={handleConfirmPassword}
            placeholder="Confirm Password"
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            size="large"
          />
        </div>

        <PasswordStrength password={password} confirmPassword={confirmPassword} />

        <div style={{ marginTop: 10 }}>
          <Button
            style={{ width: "100%" }}
            loading={loading}
            type="primary"
            onClick={signup}
            size="large"
          >
            <LoginOutlined />
            Sign up
          </Button>

          <div style={{ marginTop: 15 }}>
            Already have an account? <Link to="/">Login</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("appState")(Signup);
