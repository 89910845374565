import React, { useState } from "react";
import { Modal, Button } from "antd";
import { DeleteOutlined } from '@ant-design/icons'
import { callApi } from "../libs/api";
import PropTypes from "prop-types";

function GDeleteButton(props) {
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const deleteRow = () => {
    const url =
      "/api/" + props.endPoint + "/" + props.deleteId;
    callApi(url, "delete").then(data => {
      props.reload();
    });
    setShowConfirmModal(false)
  };

  const closeConfirmModal = () => {
    setShowConfirmModal(false)
  };

  const deletePrompt = () => {
    setShowConfirmModal(true)
  };


  return (
    <span>
      <Button type="danger" onClick={deletePrompt} size="small">
        <DeleteOutlined />
      </Button>
      <Modal
        title="Delete Confirmation"
        visible={showConfirmModal}
        onOk={deleteRow}
        onCancel={closeConfirmModal}
        okType="danger"
      >
        Are you sure you want to delete?
      </Modal>
    </span>
  );
}


GDeleteButton.propTypes = {
  endPoint: PropTypes.string.isRequired,
  deleteId: PropTypes.string.isRequired,
  reload: PropTypes.func
};

export default GDeleteButton;
