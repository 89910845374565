import React, { useState, useEffect } from "react";
import { callApi } from "../libs/api";
import { Button, Table, message } from "antd";
import CameraModal from "./modal";
import GDeleteButton from "../Comp/GDeleteButton";
import { PlusCircleOutlined } from "@ant-design/icons";

function CameraList(props) {
  const [cameras, setCameras] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [cameraID, setCameraID] = useState(0);

  useEffect(() => {
    loadCameras();
  }, []);

  const loadCameras = () => {
    setLoading(true);
    setCameraError(false);
    const url = "/api/camera/query/";
    callApi(url, "get")
      .then((data) => {
        setLoading(false);
        setCameras(data);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        message.error("Error getting records")
        setLoading(false);
        setCameraError(true);
        setCameras([]);
      });
  };

  const close = () => {
    loadCameras();
    setShowDialog(false);
  };

  const handleNewCamera = () => {
    setCameraID(0);
    setShowDialog(true);
  };

  const updateCamera = (id) => {
    setCameraID(id);
    setShowDialog(true);
  };

  const columns = [
    {
      title: "Camera Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Camera ID",
      dataIndex: "_id",
      key: "_id",
    },
    {
      title: "IP address",
      dataIndex: "ipAddress",
      key: "ipAddress",
    },
    {
      title: "Last ping",
      dataIndex: "last_ping",
      key: "last_ping",
    },
    {
      title: "Edit",
      key: "edit",
      render: (text, camera) => (
        <Button onClick={() => updateCamera(camera._id)}>Edit</Button>
      ),
    },
    {
      title: "Delete",
      key: "delete",
      render: (text, camera) => (
        <GDeleteButton
          loading={loading}
          endPoint="camera"
          deleteId={camera._id}
          reload={loadCameras}
        />
      ),
    },
  ];

  return (
    <div style={{ margin: 30 }}>
      <h3>Cameras</h3>
      <Button
        type="primary"
        style={{ marginBottom: 7 }}
        onClick={handleNewCamera}
      >
        <PlusCircleOutlined />
        Add Camera{" "}
      </Button>

      {cameraError && "Camera Error"}

      <Table
        loading={loading}
        rowKey="_id"
        dataSource={cameras}
        columns={columns}
      />

      <CameraModal showDialog={showDialog} close={close} cameraID={cameraID} />
    </div>
  );
}

export default CameraList;
