import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Tag, Alert, Spin } from "antd";
import { callApi } from "../libs/api";
import getTagColor from "./getTagColor";
import ImageModal from "./ImageModal";
import ImageDateTime from "./ImageDateTime";
import PredictionBar from "./PredictionBar";
import PredictionLabel from "./PredictionLabel";

const ImageGrid = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [imageCount, setImageCount] = useState(0);

  useEffect(() => {
    console.log("new model");
    queryImages();
  }, [props.imageIDs]);


  const queryImages = () => {
    /*if (!props.appState.cameraID) {
      return;
    }*/

    // if (props.appState.modelID && props.appState.showModelErrors) {
    //   queryModelErrors();
    //   return;
    // }
    setLoading(true);
    setError(false);
    props.appState.setImageList([]);
    const url = "/api/image/queryByIDList";
    //const body = buildQueryBody();
    const body = {ids: props.imageIDs};

    callApi(url, "POST", body)
      .then((data) => {
        setLoading(false);
        setError(false);
        setImageCount(data.count);
        console.log(JSON.stringify(data));
        
        props.appState.setImageList(data);
        // setSelectedImages(new Array(data.count));
        // setSelectedImageIds([]);
        // setSelectAll("Select All");
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoading(false);
        setError(true);
        props.appState.setImageList([]);
      });
  };

  const goToImage = (offset) => {
    const nextIndex = props.appState.imageIdx + offset;
    if (nextIndex < 0) {
      return;
    } else if (nextIndex + 1 > props.appState.imageList.length) {
      return;
    }
    props.appState.setImageIdx(nextIndex);
  };

  const canUserEdit = (img) => {
    if (
      props.appState.isUserAdmin() ||
      img.lastUpdateUser === props.appState.userName ||
      img.lastUpdateUser === undefined
    ) {
      return true;
    }
    return false;
  };

  const closeImageDialog = () => {
    setShowImageDialog(false);
  };

  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <div>{loading && <Spin size="large" />}</div>
        <div>
          {error && <Alert message="Error loading data" type="error" />}
        </div>
      </div>

      <div style={{ display: "flex", flexWrap: "wrap", margin: 20 }}>
        {props.appState.imageList?.map((img, idx) => (
          <div
            style={{
              flexGrow: 1,
              width: 290,
              marginRight: 3,
              marginBottom: 18,
            }}
            key={img._id}
          >
            <div>
              <a
                onClick={() => {
                  setShowImageDialog(true);
                  props.appState.setImageIdx(idx);
                }}
              >
                <img
                  alt={img._id}
                  style={{ width: "100%" }}
                  src={img.thumbImageUrl}
                />
              </a>
              {img?.prediction?.length > 0 && (
                <PredictionBar image={img} model_id={props.modelID} />
              )}
            </div>
            <div className="imageSubLine">
              {canUserEdit(img) && (
                <span>
                  {/* <Checkbox
                  checked={selectedImages[idx]}
                  onChange={(evt) => checkImage(evt, idx)}
                  style={{ marginRight: 3 }}
                > */}
                  <ImageDateTime image={img} />
                  {/* </Checkbox> */}
                </span>
              )}
              {!canUserEdit(img) && <ImageDateTime image={img} />}
              <PredictionLabel image={img} model_id={props.modelID} />
              <div>
                {img?.tags?.length > 0 && (
                  <div
                    style={
                      img?.ground_truth?.length > 0 ? { float: "right" } : {}
                    }
                  >
                    <span style={{ fontWeight: "bold" }}></span>{" "}
                    {img.tags &&
                      img.tags.map((tag, idx) => (
                        <Tag
                          key={idx}
                          color={getTagColor(tag)}
                          style={{ marginBottom: 5 }}
                        >
                          {tag}
                        </Tag>
                      ))}
                  </div>
                )}
                {img?.ground_truth?.length > 0 && (
                  <div>
                    <span style={{ fontWeight: "bold" }}></span>{" "}
                    {img.ground_truth &&
                      img.ground_truth.map((tag, idx) => (
                        <Tag
                          key={idx}
                          color={getTagColor(tag)}
                          style={{ marginBottom: 5 }}
                        >
                          {tag}
                        </Tag>
                      ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        ))}

        <ImageModal
          showDialog={showImageDialog}
          close={closeImageDialog}
          goToImage={goToImage}
        />
      </div>
    </div>
  );
};

export default inject("appState")(observer(ImageGrid));
