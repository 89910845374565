import React, { useState, useEffect, useRef } from "react";
import { observer, inject } from "mobx-react";
import { Select } from "antd";
import { callApi } from "../libs/api";

function GroundTruthSelect(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const isInitialMount = useRef(true);

  useEffect(() => {
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      props.appState.setGroundTruth("");
    }

    load();
  }, [props.appState.cameraID]);

  const load = () => {
    if(props.appState.cameraID.length === 0){
      return
    }
    setLoading(true);
    setError(false);
    const url = "/api/groundTruth/" + props.appState.cameraID;
    callApi(url, "get")
      .then(data => {
        data.sort();
        props.appState.setGroundTruthList(data);
        setLoading(false);        
      })
      .catch(err => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoading(false);
        setError(true);
        props.appState.setGroundTruthList([]);
      });
  };

  const handleChange = val => {
    props.appState.setGroundTruth(val);
  };

  return (
    <div>
      {error && "Error"}
      <Select
        value={props.appState.groundTruth}
        onChange={handleChange}
        style={{ width: "100%" }}
        allowClear={true}
        loading={loading}
        disabled={props.disabled}
      >
        <Select.Option value="">All Images</Select.Option>
        <Select.Option value="searchGroundTruthKeyX">
          No Ground Truth
        </Select.Option>
        {props.appState.groundTruthList.map(tag => {
          return (
            <Select.Option value={tag} key={tag}>
              {tag}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

export default inject("appState")(observer(GroundTruthSelect));
