import React, { useState, useEffect } from "react";
import { Tag, Spin, AutoComplete, message, Input } from "antd";
import { callApi } from "../libs/api";
import getTagColor from "./getTagColor";
import { CloseOutlined } from "@ant-design/icons";
import { observer, inject } from "mobx-react";

function TagEdit(props) {
  const [tagLoading, setTagLoading] = useState(false);
  const [tagError, setTagError] = useState(false);
  const [tagBuffer, setTagBuffer] = useState("");
  const [newTag, setNewTag] = useState("");
  const [masterTags, setMasterTags] = useState([]);

  let image = {};
  if (props.appState.imageList.length > props.appState.imageIdx){
    image = props.appState.imageList[props.appState.imageIdx];
  }
    
  let tags = [];
  if (props.dbTagType in image) {
    tags = image[props.dbTagType];
  }

  const handleNewTagChange = (tag) => {
    setTagBuffer(tag);
  };

  const newTagEnter = (evt) => {
    setNewTag(evt.target.value);
  };

  const handleTagSelect = (tag) => {
    setTagBuffer(tag);
    setNewTag(tag);
  };

  useEffect(() => {
    processNewTag();
  }, [newTag]);

  const processNewTag = () => {
    if (newTag.length === 0) {
      return;
    }
    if (tags) {
      const matchArray = tags.filter(function (item) {
        return item === newTag;
      });
      if (matchArray.length > 0) {
        setNewTag("");
        message.error("No duplicate tags");
        return;
      }
    }

    if (props.altTags) {
      const matchArray2 = props.altTags.filter(function (item) {
        return item === newTag;
      });
      if (matchArray2.length > 0) {
        setNewTag("");
        if(props.tagType === "groundTruth"){
          message.error("Ground Truth conflicts with tag.");
        }else{
          message.error("Tag conflicts with Ground Truth.");
        }
        
        return;
      }
    }

    setTagLoading(true);
    setTagError(false);

    const url = "/api/" + props.tagType;
    const body = {
      tag: newTag,
      image_id: props.selected_image._id,
    };
    //todo store the index to avoid race condition.
    const taggedIndex = props.appState.imageIdx;
    callApi(url, "POST", body)
      .then((partialImageRec) => {
        setTagLoading(false);
        props.appState.setImageTags(
          taggedIndex,
          props.dbTagType,
          partialImageRec[props.dbTagType]
        );
        setNewTag("");
        setTagBuffer("");
        //in case there is a change
        getTagMasterList();
      })
      .catch((err) => {
        console.error(
          "Error: processNewTag callApi() -> url: " + url + " error: " + err
        );
        setTagLoading(false);
        setTagError(true);
      });
  };

  const canUserEdit = () => {
    if (
      props.appState.isUserAdmin() ||
      props.selected_image.lastUpdateUser === props.appState.userName ||
      props.selected_image.lastUpdateUser === undefined
    ) {
      return true;
    }
    return false;
  };

  const removeTag = (tag) => {
    setTagLoading(true);
    setTagError(false);

    const url = "/api/" + props.tagType + "/removeTag";
    const body = {
      tag: tag,
      image_id: props.selected_image._id,
    };
    const deletedIndex = props.appState.imageIdx;
    callApi(url, "DELETE", body)
      .then((partialImageRec) => {
        setTagLoading(false);
        props.appState.setImageTags(
          deletedIndex,
          props.dbTagType,
          partialImageRec[props.dbTagType]
        );
        //in case there is a change
        getTagMasterList();
      })
      .catch((err) => {
        console.error(
          "Error: removeTag -> callApi() -> url: " + url + " error: " + err
        );
        setTagLoading(false);
        setTagError(true);
      });
  };

  useEffect(() => {
    getTagMasterList();
  }, [props.selected_image]);

  const getTagMasterList = () => {
    //groundTruth or tag
    //if(props.tagType)
    if (props.selected_image.cameraID === undefined) {
      console.log('no camera id???')
      return;
    }
    const url = "/api/" + props.tagType + "/" + props.selected_image.cameraID;
    callApi(url, "get")
      .then((data) => {
        data.sort();
        if (props.tagType === "tag") {
          props.appState.setTagList(data);
        } else {
          props.appState.setGroundTruthList(data);
        }
        const tagData = data.map((itm) => {
          return { value: itm };
        });
        setMasterTags(tagData);
      })
      .catch((err) => {
        console.error(
          "Error: getTagMasterList -> callApi() -> url: " +
            url +
            " error: " +
            err
        );
        setMasterTags([]);
      });
  };


  return (
    <div>
      <div>
        <AutoComplete
          options={masterTags}
          style={{ width: "100%" }}
          placeholder="Add tag and press enter"
          filterOption={(inputValue, option) => {
            return option.value
              .toUpperCase()
              .startsWith(tagBuffer.toUpperCase());
          }}
          onSelect={handleTagSelect}
          onChange={handleNewTagChange}
          value={tagBuffer}
          disabled={!canUserEdit()}
        >
          <Input
            type="text"
            allowClear={true}
            onPressEnter={newTagEnter}
            disabled={!canUserEdit()}
          />
        </AutoComplete>
      </div>
      <div style={{ marginTop: 10, marginRight: 5 }}>
        {tagLoading && <Spin />}
        {tagError && "Error Loading Tags..."}
        {tags &&
          tags.map((tag, idx) => (
            <Tag key={idx} color={getTagColor(tag)} style={{ marginBottom: 5 }}>
              {tag}{" "}
              {canUserEdit() && (
                <CloseOutlined onClick={() => removeTag(tag)} />
              )}
            </Tag>
          ))}
      </div>
    </div>
  );
}

export default inject("appState")(observer(TagEdit));
