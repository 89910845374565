import React, { useState, useEffect } from "react";
import getTagPredictionColor from "./getTagPredictionColor";
import {Progress} from 'antd'

/* 
import PredictionLabel from './PredictionLabel'
<PredictionLabel image={img} model_id={this.state.model_id} /> */

function PredictionBar(props) {
  const [prediction, setPrediction] = useState({
    prediction: "",
    confidence: 0
  });

  useEffect(() => {
    if (!props.model_id) {
      setPrediction(props.image.prediction[0]);
      return;
    }
    const prediction = props.image.prediction.find(
      pre => pre.model_id === props.model_id
    );
    if (prediction === undefined) {
      setPrediction({ prediction: "", confidence: 0 });
      return;
    }
    setPrediction(prediction);
  }, [props.image, props.model_id]);

  return (
    <span>
      {props.image.prediction !== undefined &&
        props.image.prediction.length > 0 && (
            <Progress
            percent={parseInt(prediction.confidence * 100)}
            status={getTagPredictionColor(prediction)}
            showInfo={false}
          />
        )}
    </span>
  );
}

export default PredictionBar;
