import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Link,
  Switch,
} from "react-router-dom";
import "./App.css";
import Image from "./image";
import CameraList from "./camera";
import Login from "./users/Login";
import Signup from "./users/Signup";
import ForgotPassword from "./users/ForgotPassword";
import Users from "./users";
import ChangePassword from "./users/ChangePassword";
import ResetPassword from "./users/ResetPassword";
import { Menu, Button, Row, Col } from "antd";
import ModelList from "./model";
import { Provider } from "mobx-react";
import appState from "./stores/app";
import { withRouter } from "react-router";
import { inject, observer } from "mobx-react";
import ErrorBoundary from "./ErrorBoundary";
import {
  EyeOutlined,
  UserOutlined,
  DownOutlined,
  TeamOutlined,
  LockOutlined,
  LogoutOutlined,
} from "@ant-design/icons";

window._____APP_STATE_____ = appState;

class App extends Component {
  render() {
    return (
      <Provider appState={appState}>
        <Router>
          <div className="app">
            <header className="header">
              <Row>
                <Col flex="180px">
                  {" "}
                  <div className="title">
                    <Link to="/">
                      <EyeOutlined /> Observation.ai
                    </Link>
                  </div>
                </Col>
                <Col style={{ marginLeft: 'auto' }}>
                  <MainMenu />
                </Col>
              </Row>
            </header>
            <div style={{ marginTop: 50 }}>
              <ErrorBoundary>
                <Switch>
                  <Route path="/" exact component={Login} />
                  <Route path="/signup" exact component={Signup} />
                  <Route
                    path="/forgotPassword"
                    exact
                    component={ForgotPassword}
                  />
                  <Route
                    path="/resetPassword/:token"
                    exact
                    component={ResetPassword}
                  />
                  <PrivateRoute2 path="/image" exact component={Image} />
                  <PrivateRoute2
                    path="/image/:camera_id"
                    exact
                    component={Image}
                  />
                  <PrivateRoute2
                    path="/image/:camera_id/:sort/:start"
                    exact
                    component={Image}
                  />
                  <PrivateRoute2
                    path="/image/:camera_id/:sort/:start/:end"
                    component={Image}
                  />
                  <PrivateRoute2 path="/camera" exact component={CameraList} />
                  <PrivateRoute2 path="/train" exact component={ModelList} key={"trainPath"} />
                  <PrivateRoute2 path="/model/:model" exact component={ModelList} key={"modelPath"} />
                  <PrivateRoute2 path="/users" exact component={Users} />
                  <PrivateRoute2
                    path="/changePassword"
                    exact
                    component={ChangePassword}
                  />
                  <Route component={NoMatch} />
                </Switch>
              </ErrorBoundary>
            </div>
          </div>
        </Router>
      </Provider>
    );
  }
}

const NoMatch = ({ location }) => (
  <div>
    <h3 style={{ margin: 50 }}>
      <div style={{ fontSize: 100 }}>404</div>
      No match for <code>{location.pathname}</code>
      <div style={{ marginTop: 10 }}>
        <Link to="/">
          <Button type="primary">Home</Button>
        </Link>
      </div>
    </h3>
  </div>
);

const MainMenu = inject("appState")(
  observer((props) => {
    if (!props.appState.isLoggedIn()) return <span></span>;

    return (
      <Menu mode="horizontal" style={{ width: 415 }}>
        <Menu.Item key={1}>
          <Link to="/image">Images</Link>
        </Menu.Item>
        {props.appState.userAdmin && (
          <Menu.Item key={2}>
            <Link to="/train">Models</Link>
          </Menu.Item>
        )}
        {props.appState.userAdmin && (
          <Menu.Item key={4}>
            <Link to="/camera">Cameras</Link>
          </Menu.Item>
        )}

        <Menu.SubMenu key={5}
          title={
            <span>
              <UserOutlined style={{ marginRight: 10 }} />
              Account
              <DownOutlined style={{ marginLeft: 7, fontSize: 10 }} />
            </span>
          }
        >
          <Menu.Item key="userName"> {props.appState.userName}</Menu.Item>
          {props.appState.userAdmin && (
            <Menu.Item key="users">
              <Link to="/users">
                <TeamOutlined style={{ marginRight: 8 }} />
                My Team
              </Link>
            </Menu.Item>
          )}
          <Menu.Item key="changePassword">
            <Link to="/changePassword">
              <LockOutlined  /> Change Password
            </Link>
          </Menu.Item>
          <Menu.Item key="logout">
            <MyLogout />
          </Menu.Item>
        </Menu.SubMenu>
      </Menu>
    );
  })
);

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        rest.appState.isLoggedIn() ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

const PrivateRoute2 = inject("appState")(PrivateRoute);

const MyLogout = inject("appState")(
  withRouter(({ history, appState }) => (
    <a
      onClick={() => {
        appState.reset();
        localStorage.removeItem("access_token");
        localStorage.removeItem("userName");
        localStorage.removeItem("userAdmin");
        localStorage.removeItem("customerID");

        sessionStorage.removeItem("access_token");
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("userAdmin");
        sessionStorage.removeItem("customerID");

        history.push("/");
      }}
    >
      <LogoutOutlined style={{ marginRight: 8 }} />
      Sign out
    </a>
  ))
);

export default App;
