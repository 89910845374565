const getTagPredictionColor = (img) => {
  if (img === undefined) {
    return "";
  }

  const tag = img.prediction;
  return getTagPredictionColor2(tag);
};

export const getTagPredictionColor2 = (tag) => {
  if (
    tag === "coil" ||
    tag === "sheet" ||
    tag === "dunnage" ||
    tag === "lowered" ||
    tag === "deer"
  ) {
    return "success";
  }
  return "exception";
};

export default getTagPredictionColor;
