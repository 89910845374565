import React, { useState }  from "react";
import { Modal, message } from "antd";
import { callApi } from "../libs/api";
//import PropTypes from "prop-types";

function DeleteImageModal(props) {
  const [processing, setProcessing] = useState(false)


  const deleteRow = () => {
    setProcessing(true)
    const body = props.selectedImageIds
    const url ="/api/image/delete"
    callApi(url, "delete", body).then(data => {
      setProcessing(false)
      props.reload();
      props.closeDeleteModal()
      message.error(data.deleteCount + ' images deleted.')
    }).catch(err => {
      console.error("Error: callApi() -> url: " + url + " error: " + err);
      setProcessing(false)
      message.error('Error deleting images.')
    });
    
  };

  return (
    <Modal
      title="Delete Confirmation"
      visible={props.showDeleteModal}
      onOk={deleteRow}
      onCancel={props.closeDeleteModal}
      okType="danger"
      confirmLoading={processing}
    >
      Are you sure you want to delete?
    </Modal>
  );
}

/*DeleteImageTab.propTypes = {
  endPoint: PropTypes.string.isRequired,
  deleteId: PropTypes.number.isRequired,
  reload: PropTypes.func
};*/

export default DeleteImageModal;
