import React, { useState, useEffect } from "react";
import { Upload, Modal, Progress, message } from "antd";
import { inject } from "mobx-react";
import { PlusSquareOutlined } from "@ant-design/icons";

function AddImageButton(props) {
  const [visible, setVisible] = useState(false);
  const [total, setTotal] = useState(0);
  const [count, setCount] = useState(0);
  const [errorCount, setErrorCount] = useState(0);

  let authToken = "";
  if (localStorage.getItem("access_token")) {
    authToken = localStorage.getItem("access_token");
  } else if (sessionStorage.getItem("access_token")) {
    authToken = sessionStorage.getItem("access_token");
  }

  useEffect(() => {
    if (total > 0) {
      if (count + errorCount === total) {
        setVisible(false);
        props.reload();
      }
    }
  }, [total, count, errorCount]);

  const fuprops = {
    name: "file",
    multiple: true,
    showUploadList: false,
    action: props.appState.imageHost + "/api/image/upload",
    headers: {
      Authorization: `Bearer ${authToken}`,
    },
    data: { cameraID: props.appState.cameraID },
    openFileDialogOnClick: !visible && props.appState.cameraID.length > 0,
    beforeUpload: (file, fileList) => {
      setVisible(true);
      setCount(0);
      setErrorCount(0);
      if (fileList) {
        setTotal(fileList.length);
      }
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        setVisible(true);
        // setCount(0);
        // setErrorCount(0);
      }
      if (info.file.status === "done") {
        setCount(count + 1);
      } else if (info.file.status === "error") {
        setErrorCount(errorCount + 1);
        //message.error(`${info.file.name} file upload failed.`);
        //console.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (
    <Upload accept="image/*" directory {...fuprops}>
      <div
        className={
          !props.menu && props.appState.cameraID
            ? "ant-btn-primary ant-btn-lg"
            : props.menu && props.appState.cameraID
            ? "subMenuItem"
            : "disabledSubMenuItem"
        }
      >
        <PlusSquareOutlined /> Add Images
      </div>
      <Modal
        visible={visible}
        title="Image Upload Progress"
        footer={null}
        onCancel={() => setVisible(false)}
      >
        Files uploaded {count} of {total}
        <Progress percent={(100 * (count / total)).toFixed(1)} />
        <div>
        {errorCount > 0 && ("Error Images " + errorCount )}
        </div>
      </Modal>
    </Upload>
  );
}

export default inject("appState")(AddImageButton);
