import React from "react";
import { CheckCircleOutlined, CloseCircleOutlined } from "@ant-design/icons";


const PasswordStrength = (props) => {
  return (
    <div>
      {props.password.length < 8 && (
        <div style={{ marginTop: 8, size: 18, color: "red" }}>
          <CloseCircleOutlined /> Password must be 8 at least characters
        </div>
      )}
      {props.password.length >= 8 && (
        <div style={{ marginTop: 8, size: 18, color: "green" }}>
          <CheckCircleOutlined /> Password must be 8 at least characters
        </div>
      )}
      {props.confirmPassword?.length === 0 && (
        <div style={{ marginTop: 8, size: 18, color: "black" }}>
          <CloseCircleOutlined /> Password must match confirmation
        </div>
      )}
      {props.confirmPassword?.length > 0 && props.password !== props.confirmPassword && (
        <div style={{ marginTop: 8, size: 18, color: "red" }}>
          <CloseCircleOutlined /> Password must match confirmation
        </div>
      )}
      {props.confirmPassword?.length > 0 && props.password === props.confirmPassword && (
        <div style={{ marginTop: 8, size: 18, color: "green" }}>
          <CheckCircleOutlined /> Password must match confirmation
        </div>
      )}

    </div>
  );
};
export default PasswordStrength;
