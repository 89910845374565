import React, { useState, useEffect } from "react";
import { Button, Input, Checkbox, message } from "antd";
import { inject } from "mobx-react";
import { callApi } from "../libs/api";
import { LoginOutlined } from "@ant-design/icons";
import { useHistory, Link } from "react-router-dom";
import { UserOutlined, LockOutlined } from "@ant-design/icons";

const Login = (props) => {
  const [userName, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [loggingIn, setLoggingIn] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  let history = useHistory();

  useEffect(() => {
    if (props.appState.loggedIn) {
      history.push("/image");
    }
  }, []);

  const login = () => {
    setLoggingIn(true);
    const url = "/open/login/login/";
    const body = { userName, password };
    callApi(url, "POST", body)
      .then((data) => {
        if (rememberMe) {
          localStorage.setItem("access_token", data.token);
          localStorage.setItem("userName", userName);
          localStorage.setItem("userAdmin", data.profile.admin);
          localStorage.setItem("customerID", data.profile.customerID);
          //document.cookie='access_token=[value]'
        } else {
          sessionStorage.setItem("access_token", data.token);
          sessionStorage.setItem("userName", userName);
          sessionStorage.setItem("userAdmin", data.profile.admin);
          sessionStorage.setItem("customerID", data.profile.customerID);
        }
        props.appState.setLoginInfo(
          userName,
          data.profile.customerID,
          data.profile.admin
        );
        history.push("/image");
      })
      .catch((err) => {
        console.error(
          "Error: login() -> callApi() -> url: " +
            url +
            " error: " +
            JSON.stringify(err)
        );
        console.trace();
        message.error("Invalid User Name or Password.");
        setLoggingIn(false);
      });
  };

  const handleUserName = (event) => {
    setUserName(event.target.value);
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMe = (evt) => {
    setRememberMe(evt.target.checked);
  };

  return (
    <div className="mybg">
      <div className="loginContainer">
        <h2>Sign in to your account</h2>
        <div>Email </div>
        <div style={{ marginTop: 6 }}>
          <Input
            autoFocus
            value={userName}
            onChange={handleUserName}
            placeholder="Email"
            prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
            size="large"
          />
        </div>
        <div style={{ marginTop: 28 }}>
          Password{" "}
          <div style={{ float: "right" }}>
            <Link to="/forgotPassword">Forgot your password?</Link>
          </div>
          <div style={{ marginTop: 6 }}>
            <Input.Password
              value={password}
              onChange={handlePassword}
              placeholder="Password"
              prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
              size="large"
            />
          </div>
        </div>
        <div style={{ marginTop: 20 }}>
          <Checkbox checked={rememberMe} onChange={handleRememberMe}>
            Stay signed in for 2 weeks
          </Checkbox>
        </div>

        <div style={{ marginTop: 12 }}>
          <Button
            style={{ width: "100%" }}
            loading={loggingIn}
            type="primary"
            onClick={login}
            size="large"
          >
            <LoginOutlined />
            Sign in
          </Button>

          <div style={{ marginTop: 15 }}>
            Don't have an account? &nbsp;<Link to="/signup">Signup</Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default inject("appState")(Login);
