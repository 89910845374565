import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import { Input, Button, Modal, message } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import { callApi } from "../../libs/api";

const GroundTruthManager = (props) => {
  const [loading, setLoading] = useState(false);
  const [showDeleteTagModal, setShowDeleteTagModal] = useState(false);
  const [deleteTag, setDeleteTag] = useState("");
  const [showRenameModal, setShowRenameModal] = useState(false);
  const [oldTag, setOldTag] = useState("");
  const [newTag, setNewTag] = useState("");
  const [hasChanged, setHasChanged] = useState(false)

  useEffect(() => {
    load();
  }, [props.appState.cameraID]);

  const load = () => {
    if (props.appState.cameraID.length === 0) {
      return;
    }
    setLoading(true);
    const url = "/api/groundTruth/" + props.appState.cameraID;
    callApi(url, "get")
      .then((data) => {
        data.sort();
        props.appState.setGroundTruthList(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoading(false);
        props.appState.setGroundTruthList([]);
        message.error("error loading ground truth");
      });
  };

  const handleRemoveTag = (tag) => {
    setShowDeleteTagModal(true);
    setDeleteTag(tag);
  };

  const removeTag = () => {
    const url = "/api/groundTruth/removeMultiTag";
    const body = { cameraID: props.appState.cameraID, tag: deleteTag };
    callApi(url, "delete", body)
      .then((data) => {
        load();
        setShowDeleteTagModal(false);
        setHasChanged(true)
        message.success("delete ground truth " + deleteTag);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        message.error("error deleting ground truth " + deleteTag);
        //props.appState.setTagList([]);
      });
  };

  const rename = () => {
    if (oldTag === newTag) {
      message.error("No change. Tags are the same");
      return;
    }
    if (newTag === "") {
      message.error("Tag is blank.");
      return;
    }
    const url = "/api/groundTruth/rename";
    const body = { newTag: newTag, oldTag, cameraID: props.appState.cameraID };
    console.log(JSON.stringify(body));

    callApi(url, "put", body)
      .then((data) => {
        load();
        setShowRenameModal(false);
        setNewTag("");
        setHasChanged(true)
        message.success("renamed tag ");
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        message.error("error renaming tag ");
      });
  };

  const handleRename = (tag) => {
    setOldTag(tag);
    setShowRenameModal(true);
  };

  const close = () => {
    if(hasChanged){
      props.reload()
      setHasChanged(false)
    }
    props.close()
  }

  return (
    <div>
      <Modal
        title="Ground Truth Manager"
        visible={props.visible}
        onOk={close}
        onCancel={close}
        width={600}
        style={{ top: 20 }}
        footer={[
          <Button key="back" onClick={close}>
            Close
          </Button>,
        ]}
      >
        <main>
          <div>
            {props.appState.groundTruthList.map((tag) => {
              return (
                <div key={tag} style={{ marginTop: 10, marginLeft: 10 }}>
                  <Button
                    type="danger"
                    size="small"
                    onClick={() => handleRemoveTag(tag)}
                    style={{ marginRight: 20 }}
                  >
                    <DeleteOutlined />
                  </Button>
                  <Button
                    size="small"
                    onClick={() => handleRename(tag)}
                    style={{ marginRight: 20 }}
                  >
                    Rename
                  </Button>
                  {tag}
                </div>
              );
            })}
          </div>
        </main>
      </Modal>

      <Modal
        title="Delete Confirmation"
        visible={showDeleteTagModal}
        onOk={removeTag}
        onCancel={() => setShowDeleteTagModal(false)}
        okType="danger"
      >
        Are you sure you want to delete {deleteTag}?
      </Modal>
      <Modal
        title="Rename Tag"
        visible={showRenameModal}
        onOk={rename}
        onCancel={() => setShowRenameModal(false)}
        okType="primary"
      >
        <div>Rename tag {oldTag} to </div>
        <Input value={newTag} onChange={(evt) => setNewTag(evt.target.value)} />
      </Modal>
    </div>
  );
};
export default inject("appState")(observer(GroundTruthManager));
