import React, { useState }  from "react";
import { Modal, message } from "antd";
import { callApi } from "../libs/api";
import { inject } from "mobx-react";
//import PropTypes from "prop-types";

function DeleteModelModal(props) {
  const [processing, setProcessing] = useState(false)


  const deleteRow = () => {
    setProcessing(true)
    const url ="/api/model/delete/" + props.modelID + '/' + props.appState.cameraID
    callApi(url, "delete").then(data => {
      setProcessing(false)
      props.reload();
      props.closeDeleteModal()
      message.error('Model deleted.')
    }).catch(err => {
      console.error("Error: callApi() -> url: " + url + " error: " + err);
      setProcessing(false)
      //message.error('Error deleting models.')
    });
    
  };

  return (
    <Modal
      title="Delete Confirmation"
      visible={props.showDeleteModal}
      onOk={deleteRow}
      onCancel={props.closeDeleteModal}
      okType="danger"
      confirmLoading={processing}
    >
      Are you sure you want to delete?
    </Modal>
  );
}

/*DeleteImageTab.propTypes = {
  endPoint: PropTypes.string.isRequired,
  deleteId: PropTypes.number.isRequired,
  reload: PropTypes.func
};*/

export default inject("appState")(DeleteModelModal);