import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button } from "antd";
import Predictions from "./predictions";
import ImageDateTime from "./ImageDateTime";
import TagEdit from "./TagEdit";
import ImageHistory from "./ImageHistory";
import { observer, inject } from "mobx-react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import ProgressiveImage from "react-progressive-image";

function ImageModal(props) {
  const [showHistory, setShowHistory] = useState(false);

  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyDown = (e) => {
    //don't navigate to next/previous pics if we are in a text box.
    if (!document.activeElement.id) {
      if (e.keyCode === 37) {
        props.goToImage(-1);
      } else if (e.keyCode === 39) {
        props.goToImage(1);
      }
    }
  };

  let image = {};
  if (props.appState.imageList.length > 0) {
    image = props.appState.imageList[props.appState.imageIdx] || {};
  }

  return (
    <Modal
      title="Image Detail"
      visible={props.showDialog}
      onOk={props.close}
      onCancel={props.close}
      width={1200}
      style={{ top: 20 }}
      footer={[
        <Button
          key="history"
          onClick={() => {
            setShowHistory(true);
          }}
        >
          Image History
        </Button>,
        <Button key="ok" onClick={props.close}>
          Close
        </Button>,
      ]}
    >
      <Row>
        <Col span={19}>
          {image._id && (
            <ProgressiveImage
              src={image.imageUrl}
              placeholder={image.thumbImageUrl}
            >
              {(src, loading) => (
                <span>
                  <img
                    src={src}
                    alt="an image"
                    style={{
                      maxWidth: 900,
                      maxHeight: 506,
                      width: "auto",
                      height: loading ? 506 : "auto"
                    }}
                  />                  
                </span>
              )}
            </ProgressiveImage>
          )}
        </Col>
        <Col span={5}>
          <div>
            <Button
              disabled={props.appState.imageIdx === 0}
              onClick={() => props.goToImage(-1)}
              style={{ width: 120 }}
            >
              <LeftOutlined />
            </Button>
            <Button
              disabled={props.appState.imageIdx === props.appState.pageSize - 1}
              onClick={() => props.goToImage(1)}
              style={{ width: 120 }}
            >
              {" "}
              <RightOutlined />
            </Button>
          </div>
          <div className="myLabel">
            <ImageDateTime image={image} />
          </div>
          <div className="myLabel">Ground Truth Tags</div>
          <TagEdit
            selected_image={image}
            tagType="groundTruth"
            dbTagType="ground_truth"
            tags={image.ground_truth}
            altTags={image.tags}
          />
          <div className="myLabel">My Tags</div>
          <TagEdit
            selected_image={image}
            tagType="tag"
            dbTagType="tags"
            tags={image.tags}
            altTags={image.ground_truth}
          />
          <div className="myLabel">Predictions</div>
          <Predictions
            prediction={image.prediction}
            imageID={image._id}
            cameraID={image.camera_id}
          />
        </Col>
      </Row>
      <ImageHistory
        show={showHistory}
        close={() => {
          setShowHistory(false);
        }}
        id={image._id}
      />
    </Modal>
  );
}

export default inject("appState")(observer(ImageModal));
