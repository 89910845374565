import React, { useState } from "react";
import { Modal, Input, message } from "antd";
import { callApi } from "../libs/api";
import isValidEmail from "./isValidEmail";

function UserModal(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [user, setUser] = useState({ user_name: "" });

  const handleUserNameChange = (evt) => {
    let u = { ...user };
    u.user_name = evt.target.value;
    setUser(u);
  };

  // useEffect(() => {
  //   if(props.cameraID === 0){
  //     setUser({uesr_name:''})
  //     return
  //   }
  //   getUser(props.cameraID)
  // }, [props.userID])

  // const getUser = (id) => {
  //   setLoading(true);
  //   setError(false);
  //   const url = "/api/user/" + id;
  //   callApi(url, "GET")
  //     .then(data => {
  //       setLoading(false);
  //       setUser(data)
  //     })
  //     .catch(err => {
  //       console.error("Error: callApi() -> url: " + url + " error: " + err);
  //       setLoading(false);
  //       setError(true);
  //     });

  // }

  const save = async () => {
    if (!isValidEmail(user.user_name)) {
      message.error("Invalid email address");
      return;
    }

    await callApi("/api/users/", "post", user)
      .then((data) => {
        message.info("User saved");
        props.close();
        setUser({ user_name: "" });
      })
      .catch((err) => {
        console.error("error saving user " + JSON.stringify(err));
        message.error("Error saving user");
      });
  };

  const closeOut = () => {
    setUser({ user_name: "" });
    props.close();
  };

  return (
    <Modal
      title="User Detail"
      visible={props.showDialog}
      onOk={save}
      onCancel={closeOut}
      width={600}
      style={{ top: 20 }}
    >
      {loading && "Loading..."}
      {error && "Error Loading..."}
      <div>
        Email{" "}
        <Input
          value={user.user_name}
          type="text"
          placeholder="Email"
          onChange={handleUserNameChange}
        />
      </div>
      <div style={{ marginTop: 8 }}>
        Labelers can
        <ul>
          <li>
            add or delete "ground truth" labels or tags to images that have not
            been updated by other users.
          </li>
        </ul>
        <div>Labelers can not</div>
        <ul>
          <li>add or delete images.</li>
          <li>add, update or delete other users.</li>
          <li>add, or delete cameras.</li>
        </ul>
        {/* <Checkbox onChange={handleCanAddChange} checked={user.canAddImages}>
          Allow user to add images?{" "}
        </Checkbox> */}
      </div>
    </Modal>
  );
}

export default UserModal;
