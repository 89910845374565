import React, { useState, useEffect } from "react";
import { inject, observer } from "mobx-react";
import {
  Select,
  InputNumber,
  Tag,
  Checkbox,
  Card,
  Button,
  message,
  Spin
} from "antd";
import { callApi } from "../libs/api";
import { SaveOutlined, PlayCircleOutlined } from "@ant-design/icons";

const Train = (props) => {
  const [saving, setSaving] = useState(false);
  const [loadingTags, setLoadingTags] = useState(false);
  const [record, setRecord] = useState({ status: 0 });
  
  const [loadingLabels, setLoadingLabels] = useState(false)
  const [labels, setLabels] = useState([])

  useEffect(() => {
    if (props.appState.cameraID) {
      loadTags();
      loadLabels()
      loadParameters();
    }
  }, [props.appState.cameraID]);

  const loadParameters = () => {
    //setLoading(true);
    //setError(false);
    const url = "/api/parameters/" + props.appState.cameraID;

    callApi(url)
      .then((data) => {
        //console.log("data: " + JSON.stringify(data));

        //setLoading(false);
        //setError(false);
        if (data) {
          setRecord(data);
          //message.info("Parameters loaded");
        } else {
          setRecord({ status: 0 });
          //message.info("No Parameters loaded");
        }
      })
      .catch((err) => {
        console.error(
          "Error loadParameters: callApi() -> url: " + url + " error: " + err
        );
        message.error("Error loading Parameters");
        //setLoading(false);
        //setError(true);
      });
  };

  const loadTags = () => {
    if (props.appState.cameraID.length === 0) {
      return;
    }
    setLoadingTags(true);
    const url = "/api/tag/" + props.appState.cameraID;
    callApi(url, "get")
      .then((data) => {
        data.sort();
        props.appState.setTagList(data);
        setLoadingTags(false);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoadingTags(false);
        props.appState.setTagList([]);
        message.error("error loading tags");
      });
  };

  const loadLabels = () => {
    if (props.appState.cameraID.length === 0) {
      return;
    }
    setLoadingLabels(true);
    const url = "/api/groundTruth/getTotals/" + props.appState.cameraID;
    callApi(url, "get")
      .then((data) => {
        setLabels(data);
        //console.log("label totals: " + JSON.stringify(data));
        
        setLoadingLabels(false);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoadingLabels(false);
        setLabels([])
        message.error("error loading labels");
      });
  };


  const trainModel = () => {
    let params = { ...record };
    //toggle status
    if ("status" in params && params.status === 0) {
      params.status = 1;
    } else {
      params.status = 0;
    }

    setRecord(params);
    saveModel(params);
  };

  const saveModel = (rec) => {
    setSaving(true);
    const url = "/api/parameters/" + props.appState.cameraID;

    callApi(url, "PUT", rec)
      .then((data) => {
        setSaving(false);
        //setError(false);
        message.info("Parameters saved");
      })
      .catch((err) => {
        console.error(
          "Error loadParameters: callApi() -> url: " + url + " error: " + err
        );
        message.error("Error while training");
        setSaving(false);
      });
  };

  const handleChange = (value, fieldName) => {
    console.log("value " + value);
    console.log("typeof value " + typeof value);
    if (value && !isNaN(value) && fieldName === "batchSize") {
      value = parseInt(value);
    }
    let item = { ...record };
    item[fieldName] = value;
    setRecord(item);
  };

  return (
    <div>
      {props.appState.cameraID && (
        <main className="main">
          <div style={{ margin: 20 }}>
            <Button onClick={() => saveModel(record)} loading={saving}>
              <SaveOutlined />
              Save
            </Button>
            {record.status === 0 && (
              <Button
                onClick={trainModel}
                style={{
                  backgroundColor: "#007C04",
                  color: "white",
                  marginLeft: 15,
                }}
                loading={saving}
              >
                <PlayCircleOutlined />
                Train
              </Button>
            )}
            {record.status !== 0 && (
              <Button
                onClick={trainModel}
                danger
                type="primary"
                style={{ marginLeft: 15 }}
                loading={saving}
              >
                Cancel Training
              </Button>
            )}
          </div>
          <Card title="Data">
            <div style={{marginBottom: 15}}>
              <div style={{ fontWeight: "bold" }}>Ground Truth Labels: </div>
              {loadingLabels && <Spin />}
              {!loadingLabels && labels.map((labelRecord, idx) => (
                  <div key={idx} style={{margin: 5}}>
                    <Tag>{labelRecord.label}</Tag> {labelRecord.count}
                  </div>
                ))}
            </div>
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1, flexBasis: "25%" }}>
                <div>Training Labels</div>

                <div>
                  <Select
                    value={record.trainingLabel}
                    onChange={(val) => handleChange(val, "trainingLabel")}
                    style={{ width: 200 }}
                    allowClear={true}
                    loading={loadingTags}
                  >
                    <Select.Option value={null} key="0">
                      No Tag
                    </Select.Option>
                    {props.appState.tagList.map((tag) => {
                      return (
                        <Select.Option value={tag} key={tag}>
                          {tag}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div style={{ flexGrow: 1, flexBasis: "25%" }}>
                <div>Number of random training examples</div>
                <div>
                  <InputNumber
                    value={record.randomTrain}
                    onChange={(val) => handleChange(val, "randomTrain")}
                    defaultValue={0}
                  />{" "}
                </div>
              </div>
              <div style={{ flexGrow: 1, flexBasis: "25%" }}>
                <div>Validation Labels</div>
                <div>
                  <Select
                    value={record.validationLabel}
                    onChange={(val) => handleChange(val, "validationLabel")}
                    style={{ width: 200 }}
                    allowClear={true}
                    loading={loadingTags}
                  >
                    <Select.Option value={null} key="0">
                      No Tag
                    </Select.Option>                    
                    {props.appState.tagList.map((tag) => {
                      return (
                        <Select.Option value={tag} key={tag}>
                          {tag}
                        </Select.Option>
                      );
                    })}
                  </Select>
                </div>
              </div>
              <div style={{ flexGrow: 1, flexBasis: "25%" }}>
                <div>Number of random validation examples</div>
                <div>
                  <InputNumber
                    value={record.randomValidation}
                    onChange={(val) => handleChange(val, "randomValidation")}
                    defaultValue={0}
                  />{" "}
                </div>
              </div>
            </div>
          </Card>
          <Card title="Preprocessing">
            <div style={{ display: "flex" }}>
              <div style={{ flexGrow: 1 }}>
                <div>Image Width </div>

                <div>
                  <InputNumber
                    value={record.width}
                    onChange={(val) => handleChange(val, "width")}
                    defaultValue={0}
                  />{" "}
                </div>
              </div>
              {/* <div>
          <Select style={{ width: 180 }}>
            <Select.Option>Center Crop to Square</Select.Option>
            <Select.Option>Rectangle</Select.Option>
          </Select>
        </div> */}
              <div style={{ flexGrow: 1 }}>
                <div>Image Height</div>
                <div>
                  <InputNumber
                    value={record.height}
                    onChange={(val) => handleChange(val, "height")}
                    defaultValue={0}
                  />{" "}
                </div>
              </div>
              {/* <div>Output volume: </div> */}
              <div style={{ flexGrow: 2 }}>
                <div> Color</div>
                <div>
                  <Select
                    value={record.color}
                    onChange={(val) => handleChange(val, "color")}
                    style={{ width: 180 }}
                  >
                    <Select.Option value={1} key="GrayScale">
                      GrayScale
                    </Select.Option>
                    <Select.Option value={3} key="RGB">
                      RGB
                    </Select.Option>
                  </Select>
                </div>
              </div>
              {/* <div>
          Testing Labels (default is all images except training and validation)
        </div> */}
              {/* <div>Data Augmentation</div>
        <div>
          <Checkbox /> mirroring
        </div>
        <div>
          <Checkbox /> flipping
        </div> */}
            </div>
          </Card>
          <Card title="Training">
            <div style={{ display: "flex" }}>
            <div style={{ flexGrow: 1, flexBasis: "25%" }}>
                <div>Epochs</div>
                <InputNumber
                  value={record.epochs}
                  onChange={(val) => handleChange(val, "epochs")}
                  defaultValue={0}
                />
              </div>
              <div style={{ flexGrow: 1, flexBasis: "25%" }}>
                <div>Batch Size </div>
                <div>
                  <InputNumber
                    value={record.batchSize}
                    onChange={(val) => handleChange(val, "batchSize")}
                  />

                  {/* <Input
                    value={record.batchSize}
                    onChange={(evt) =>
                      handleChange(evt.target.value, "batchSize")
                    }
                  /> */}
                </div>
              </div>
              <div style={{ flexGrow: 1, flexBasis: "25%" }}>
                <div>Dropout Rate (on last layer)</div>
                <div>
                  <InputNumber
                    value={record.dropout}
                    onChange={(val) => handleChange(val, "dropout")}
                    defaultValue={0}
                  />
                </div>
              </div>
              <div style={{ flexGrow: 1, flexBasis: "25%" }}>
                <div>Architecture</div>
                <div>
                  <Select style={{ width: 180 }}>
                    <Select.Option value="shalloNet">
                      ShallowNet (fixed position cameras)
                    </Select.Option>
                    <Select.Option value="resnet34">ResNet 34</Select.Option>
                    <Select.Option value="resnet50">ResNet 50</Select.Option>
                  </Select>
                </div>
              </div>
            </div>
            <div style={{ display: "flex", marginTop: 10 }}>
            <div style={{ flexGrow: 1, flexBasis: "25%" }}>
                <div>Optimizer</div>
                <div>
                  <Select
                    value={record.optimizer}
                    onChange={(val) => handleChange(val, "optimizer")}
                    style={{ width: 180 }}
                  >
                    <Select.Option value="SGD">SGD</Select.Option>
                    <Select.Option value="RMSProp">RMSProp</Select.Option>
                    <Select.Option value="Adam">Adam</Select.Option>
                    <Select.Option value="Adadelta">Adadelta</Select.Option>
                    <Select.Option value="Adagrad">Adagrad</Select.Option>
                    <Select.Option value="Adamax">Adamax</Select.Option>
                    <Select.Option value="Nadam">Nadam</Select.Option>
                    <Select.Option value="Ftrl">Ftrl</Select.Option>
                  </Select>
                </div>
              </div>
              <div style={{ flexGrow: 1, flexBasis: "25%" }}>
                <div>Learning Rate</div>
                <div>
                  <InputNumber
                    value={record.learningRate}
                    onChange={(val) => handleChange(val, "learningRate")}
                    defaultValue={0}
                  />
                </div>
              </div>

              {record.optimizer === "SGD" && (
                <>
                  <div style={{ flexGrow: 1 }}>
                    <div>momentum</div>
                    <div>
                      <InputNumber />
                    </div>
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <div>nesterov</div>
                    <div>
                      <Checkbox />
                    </div>
                  </div>
                </>
              )}
            </div>
          </Card>
        </main>
      )}
    </div>
  );
};
export default inject("appState")(observer(Train));
