import React, { useState } from "react";
import { Button, Input, message } from "antd";
import { callApi } from "../libs/api";
import { Link } from "react-router-dom";

const ForgotPassword = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);

  const handleSubmit = () => {
    //check for valid email.

    setLoading(true);
    const url = "/open/login/forgotPassword/";
    const body = { userName: email };
    callApi(url, "PUT", body)
      .then((data) => {
        message.info("Email sent.");
        setLoading(false);
      })
      .catch((err) => {
        console.error(
          "Error: callApi() -> url: " + url + " error: " + JSON.stringify(err)
        );
        message.error("Error with forgot password.");
        setLoading(false);
      });
  };

  const handleEmail = (event) => {
    setEmail(event.target.value);
  };

  return (
    <div className="mybg">
      <div className="loginContainer" style={{height: 250}}>
        <h2>Forgot Password</h2>
        <div>
          Email{" "}
          <Input
            autoFocus
            value={email}
            onChange={handleEmail}
            placeholder="Password"
            size="large"
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <Button
            style={{ width: "100%" }}
            loading={loading}
            type="primary"
            onClick={handleSubmit}
            size="large"
          >
            Reset Password
          </Button>
        </div>
        <div style={{ marginTop: 10 }}>
          <Link to="/">Back to login</Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
