import React, { useState, useEffect, useRef } from "react";
import { observer, inject } from "mobx-react";
import { Select } from "antd";
import { callApi } from "../libs/api";

function TagSelect(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  const isInitialMount = useRef(true);

  useEffect(() => {
    //if mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      props.appState.setTag("");
    }

    load();
  }, [props.appState.cameraID]);

  const load = () => {
    if (props.appState.cameraID.length === 0) {
      return;
    }
    setLoading(true);
    setError(false);
    const url = "/api/tag/" + props.appState.cameraID;
    callApi(url, "get")
      .then((data) => {
        data.sort();
        props.appState.setTagList(data);
        setLoading(false);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoading(false);
        setError(true);
        props.appState.setTagList([]);
      });
  };

  const handleChange = (val) => {
    props.appState.setTag(val);
  };

  return (
    <div>
      {error && "Error"}
      <Select
        value={props.appState.tag}
        onChange={handleChange}
        style={{ width: "100%" }}
        allowClear={true}
        loading={loading}
      >
        <Select.Option value="">All</Select.Option>
        <Select.Option value="XsearchNoTagKeyX">No Tags</Select.Option>
        {props.appState.tagList.map((tag) => {
          return (
            <Select.Option value={tag} key={tag}>
              {tag}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

export default inject("appState")(observer(TagSelect));
