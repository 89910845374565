import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react"
import { Select } from "antd";
import { callApi } from "../libs/api";

function CameraSelect(props) {
  const [cameras, setCameras] = useState([]);
  const [loadingCameras, setLoadingCameras] = useState(false);
  const [cameraError, setCameraError] = useState(false);

  useEffect(() => {
    loadCameras();
  }, []);

  const loadCameras = () => {
    setLoadingCameras(true);
    setCameraError(false);
    const url = "/api/camera/query/";
    callApi(url, "get")
      .then(data => {
        setLoadingCameras(false);
        setCameras(data);
      })
      .catch(err => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoadingCameras(false);
        setCameraError(true);
        setCameras([]);
      });
  };

  const handleCameraChange = val => {
    props.appState.setCameraID(val)
  };

  useEffect(() => {
    props.reload()
    props.appState.fetchModels()
  }, [props.appState.cameraID]);

  return (
    <div >
      {cameraError && "Camera Error"}
      <Select
        value={props.appState.cameraID}
        onChange={handleCameraChange}
        style={{ width: "100%" }}
        loading={loadingCameras}
      >
        {cameras.map(camera => (
          <Select.Option value={camera._id} key={camera._id}>
            {camera.name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default inject("appState")(observer(CameraSelect))
