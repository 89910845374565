import React from "react";
import { CloudUploadOutlined } from "@ant-design/icons";
import AddImageButton from "./AddImageButton";

const NoImagePanel = (props) => {
  return (
    <div>
      <div style={{ width: 400, margin: "100px auto 0px auto" }}>
        <h2>You have no images</h2>
        <CloudUploadOutlined style={{ marginRight:10 }}/>
        Upload images to your project.
        <div style={{margin: "20px auto 0 auto"}}>
          <AddImageButton reload={props.reload} menu={false} />
        </div>
      </div>
    </div>
  );
};
export default NoImagePanel;
