import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react"
import { Select } from "antd";
import { callApi } from "../libs/api";

function UserSelect(props) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {
    loadData();
  }, []);

  const loadData = () => {
    setLoading(true);
    setError(false);
    const url = "/api/users/";
    callApi(url, "get")
      .then(data => {
        setError(false);
        setLoading(false);
        setUsers(data);
      })
      .catch(err => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoading(false);
        setError(true);
        setUsers([]);
      });
  };

  const handleChange = val => {
    props.appState.setUserID(val)
  };

  /*useEffect(() => {
    props.reload()
  }, [props.appState.userID]);*/

  return (
    <div >
      {error && "Error"}
      <Select
        value={props.appState.userID}
        onChange={handleChange}
        allowClear={true}
        style={{ width: "100%" }}
        loading={loading}
      >
        {users.map(user => (
          <Select.Option value={user.user_name} key={user._id}>
            {user.user_name}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
}

export default inject("appState")(observer(UserSelect))
