import React, { useState, useEffect } from "react";
import { useHistory, useParams } from "react-router-dom";
import { Button, Collapse } from "antd";
import { callApi } from "../libs/api";
import CameraSelect from "../image/CameraSelect";
import moment from "moment";
import { observer, inject } from "mobx-react";
import { Card, message } from "antd";
import DeleteModelModal from "./DeleteModelModal";
import ImageGrid from "../image/ImageGrid";
import {
  DeleteOutlined,
  CloseOutlined,
  CheckOutlined,
  SyncOutlined,
  PlayCircleOutlined,
} from "@ant-design/icons";
import Train from "./Train";

function ModelList(props) {
  const { Panel } = Collapse;
  const [models, setModels] = useState([]);
  const [loadingModels, setLoadingModels] = useState(false);
  const [modelError, setModelError] = useState(false);
  const [selectedIdx, setSelectedIdx] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [kerasModel, setKerasModel] = useState({});
  const [realtimeErrors, setRealtimeErrors] = useState(null);
  const [fetching, setFetching] = useState(false);
  const [newImages, setNewImages] = useState([]);
  const [showTrain, setShowTrain] = useState(true);
  const [trainingLabelCount, setTrainingLabelCount] = useState([]);

  let history = useHistory();
  let { model } = useParams()

  const reload = () => {
    setSelectedIdx(0);
    loadModels();
  };

  useEffect(() => {
    if (!model) {
      loadTrain()
    } else {
      setShowTrain(false)
      setSelectedIdx(0);
    }
  }, [])

  useEffect(() => {
    setRealtimeErrors(null);
    if (models && models.length > 0 && "keras_model" in models[selectedIdx]) {
      setKerasModel(JSON.parse(models[selectedIdx].keras_model));
    }
    initNewImages();
  }, [models, selectedIdx]);

  const loadModels = () => {
    if (props.appState.cameraID === "") {
      return;
    }
    setLoadingModels(true);
    setModelError(false);
    const url = "/api/model/" + props.appState.cameraID;
    setModels([]);
    callApi(url, "get")
      .then((data) => {
        setLoadingModels(false);
        setModels(data);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoadingModels(false);
        setModelError(true);
        setModels([]);
      });
  };

  const selectModel = (idx) => {
    setShowTrain(false);
    setSelectedIdx(idx);
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const initNewImages = () => {
    if (selectedIdx >= models.length - 1) {
      setNewImages([]);
      return;
    }
    //diff = A.filter(function(x) { return B.indexOf(x) < 0 })
    //filter current model where the image doesn't exist in previous model.
    setNewImages(
      models[selectedIdx].train_image_ids.filter(
        (i) => models[selectedIdx + 1].train_image_ids.indexOf(i) < 0
      )
    );
  };

  const getRemovedImages = () => {
    if (selectedIdx >= models.length - 1) {
      return [];
    }
    return models[selectedIdx + 1].train_image_ids.filter(
      (i) => models[selectedIdx].train_image_ids.indexOf(i) < 0
    );
  };

  const reversePublish = () => {
    if (models && models.length > 0) {
      if ("published" in models[selectedIdx]) {
        models[selectedIdx].published = !models[selectedIdx].published;
      } else {
        models[selectedIdx].published = true;
      }

      changePublish(models[selectedIdx].published, models[selectedIdx]._id);
    }
  };

  const changePublish = (published, modelID) => {
    const url = "/api/model/publish";
    const body = { modelID: modelID, published: published };
    callApi(url, "put", body)
      .then((data) => {
        //update the model record.
        loadModels();
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        message.error("publish failed");
      });
  };

  const getRealtime = () => {
    setFetching(true);
    const url =
      "/api/model/realTime/" +
      props.appState.cameraID +
      "/" +
      models[selectedIdx]._id;
    callApi(url, "get")
      .then((data) => {
        setFetching(false);
        console.log(JSON.stringify(data));
        setRealtimeErrors(data);
      })
      .catch((err) => {
        setFetching(false);
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        message.error("getting real time stats failed");
      });
  };

  const goToErrors = () => {
    props.appState.setModelID(models[selectedIdx]._id);
    props.appState.setGroundTruth("");
    props.appState.setTag("");
    props.appState.setImageSort("high_confidence");
    props.appState.setShowModelErrors(true);
    props.appState.setShowTrainingImages(false);
    //clear user, last & first dates.
    history.push("/image/");
  };

  const goToLowConfidence = () => {
    props.appState.setModelID(models[selectedIdx]._id);
    props.appState.setGroundTruth("");
    props.appState.setTag("");
    props.appState.setImageSort("low_confidence");
    props.appState.setShowModelErrors(false);
    props.appState.setShowTrainingImages(false);
    //clear user, last & first dates.
    history.push("/image/");
  };

  const goToTraining = () => {
    props.appState.setModelID(models[selectedIdx]._id);
    props.appState.setGroundTruth("");
    props.appState.setTag("");
    props.appState.setImageSort("newest");
    props.appState.setShowModelErrors(false);
    props.appState.setShowTrainingImages(true);
    //clear user, last & first dates.
    history.push("/image/");
  };

  const loadTrain = () => {
    setShowTrain(true);
    setSelectedIdx(0);
  };

  useEffect(() => {
    getTrainingStats();
  }, [selectedIdx]);

  ///api/model/getTrainingStats/:modelID
  const getTrainingStats = () => {
    if (!models[selectedIdx]) {
      return;
    }
    if (models[selectedIdx].training_labels) {
      return;
    }
    const url = "/api/model/getTrainingStats/" + models[selectedIdx]._id;
    callApi(url, "get")
      .then((data) => {
        //setFetching(false);
        setTrainingLabelCount(data);
      })
      .catch((err) => {
        //setFetching(false);
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        message.error("Run test failed");
      });
  };

  const runTest = () => {
    const url = "/api/model/runTest/" + models[selectedIdx]._id;
    callApi(url, "put")
      .then((data) => {
        //setFetching(false);
        console.log(JSON.stringify(data));
        message.info("Running Test");
      })
      .catch((err) => {
        //setFetching(false);
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        message.error("Run test failed");
      });
  };

  return (
    <div className="subPage">
      <nav className="sidemenu">
        <div className="sideNavItems">
          <div className="myLabelTop">Cameras</div>
          <CameraSelect reload={reload} />
        </div>
        {props.appState.cameraID && (
          <>
            <div className="sideNavItems">
              <div className="myLabel">
                <span>
                  Models
                  <Button onClick={reload} style={{ float: "right" }}>
                    <SyncOutlined />
                  </Button>
                </span>
              </div>
            </div>

            <div
              onClick={() => setShowTrain(true)}
              className={showTrain ? "selectedModel"
                : "modelBox"}
              style={{
                padding: 15,
                borderBottom: "2px solid rgb(212, 212, 212)",
              }}>
              <PlayCircleOutlined style={{ marginRight: 5 }} />
              Train New Model
            </div>
          </>
        )}
        {loadingModels && "loading..."}
        {modelError && "Model Error"}
        {models.map((model, modelIdx) => (
          <div
            onClick={() => selectModel(modelIdx)}
            key={model._id}
            className={
              !showTrain && selectedIdx === modelIdx
                ? "selectedModel"
                : "modelBox"
            }
            style={{
              padding: 15,
              borderBottom: "2px solid rgb(212, 212, 212)",
            }}
          >
            {" "}
            <div>
              Trained: {moment(model.run_time).utc().format("MM/DD/YYYY HH:mm")}
            </div>
            <div>
              {" "}
              {model.published && (
                <span
                  style={{
                    border: "1px solid rgb(180, 180, 180)",
                    padding: 2,
                    borderRadius: 3,
                  }}
                >
                  Published
                </span>
              )}
            </div>
            {/* <div>Validation {(model.val_acc * 100).toFixed(2)}%</div> */}
            {"test_correct" in model && (
              <div>
                {" "}
                Test :{" "}
                {(
                  (model.test_correct /
                    (model.test_correct + model.test_incorrect)) *
                  100
                ).toFixed(2)}
                {" % "}{" "}
                <span style={{ marginLeft: 17 }}>
                  (
                  {(model.test_correct + model.test_incorrect).toLocaleString()}
                  )
                </span>
              </div>
            )}
            <div>Incorrect w/ confidence: {model.confident_incorrect}</div>
          </div>
        ))}
      </nav>
      <main className="main">
        {((showTrain && props.appState.cameraID) ||
          (models.length === 0 && !loadingModels)) && <Train />}
        {models.length > 0 && !showTrain && (
          <div className="subMenu">
            <div
              className={
                models.length > 0 ? "subMenuItem" : "disabledSubMenuItem"
              }
              style={{ width: 130 }}
              onClick={reversePublish}
            >
              {models[selectedIdx] && models[selectedIdx].published && (
                <CloseOutlined className="subButtons" />
              )}
              {models[selectedIdx] && !models[selectedIdx].published && (
                <CheckOutlined className="subButtons" />
              )}

              {models[selectedIdx] &&
                models[selectedIdx].published &&
                "Unpublish"}
              {models[selectedIdx] &&
                !models[selectedIdx].published &&
                "Publish"}
            </div>
            <div
              className={
                models.length > 0 && !models[selectedIdx].published
                  ? "subMenuItem"
                  : "disabledSubMenuItem"
              }
              style={{ width: 130 }}
              onClick={() => {
                if (models.length === 0) {
                  return;
                }
                if (models[selectedIdx].published) {
                  return;
                }
                setShowDeleteModal(true);
              }}
            >
              <DeleteOutlined className="subButtons" />
              Delete
            </div>
          </div>
        )}
        <div style={{ padding: 20 }}>
          {models.length > 0 && !showTrain && (
            <div>
              <h2>Iteration</h2>
              <div>
                Model id:{" "}
                <span style={{ fontWeight: "bold" }}>
                  {models[selectedIdx]._id}
                </span>
              </div>
              <div>
                Trained:
                <span style={{ fontWeight: "bold" }}>
                  {moment(models[selectedIdx].run_time)
                    .utc()
                    .format("MM/DD/YYYY HH:mm")}{" "}
                </span>
              </div>
              <div>
                Labels:{" "}
                {models[selectedIdx].labels.map((label, idx) => (
                  <span style={{ fontWeight: "bold" }} key={idx}>
                    {label}{" "}
                  </span>
                ))}
              </div>
              <h2 style={{ marginTop: 15 }}>Training</h2>
              <div>
                {" "}
                <Button onClick={goToTraining}>
                  Show {models[selectedIdx].train_image_ids.length} Training
                  Images
                </Button>
              </div>
              <div style={{ marginTop: 10 }}>
                <table>
                  <thead>
                    <tr style={{ backgroundColor: "#eee" }}>
                      <th
                        style={{ width: "50%", padding: 10, textAlign: "left" }}
                      >
                        Ground Truth
                      </th>
                      <th
                        style={{ width: "50%", padding: 10, textAlign: "left" }}
                      >
                        Image Count
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {models[selectedIdx].training_labels?.map((row, idx) => (
                      <tr>
                        <td>{row.ground_truth}</td>
                        <td>{row.count}</td>
                      </tr>
                    ))}

                    {!models[selectedIdx].training_labels &&
                      trainingLabelCount.map((row, idx) => (
                        <tr>
                          <td>{row.ground_truth}</td>
                          <td>{row.count}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
              <h2 style={{ marginTop: 15 }}>Validation</h2>
              <table style={{ width: 800 }}>
                <thead>
                  <tr style={{ backgroundColor: "#eee" }}>
                    <th
                      style={{ width: "25%", padding: 10, textAlign: "left" }}
                    >
                      Incorrect
                    </th>
                    <th
                      style={{ width: "25%", padding: 10, textAlign: "left" }}
                    >
                      Correct
                    </th>
                    <th
                      style={{ width: "25%", padding: 10, textAlign: "left" }}
                    >
                      Total
                    </th>
                    <th
                      style={{ width: "25%", padding: 10, textAlign: "left" }}
                    >
                      Accuracy
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td style={{ padding: 10 }}>
                      {Math.round(
                        models[selectedIdx].validation_image_ids.length *
                        (1.0 - models[selectedIdx].val_acc)
                      )}
                    </td>
                    <td style={{ padding: 10 }}>
                      {Math.round(
                        models[selectedIdx].validation_image_ids.length *
                        models[selectedIdx].val_acc
                      )}
                    </td>
                    <td style={{ padding: 10 }}>
                      {models[selectedIdx].validation_image_ids.length}
                    </td>
                    <td style={{ padding: 10 }}>
                      {models[selectedIdx].val_acc &&
                        (models[selectedIdx].val_acc * 100).toFixed(4)}{" "}
                      %
                    </td>
                  </tr>
                </tbody>
              </table>
              {"test_correct" in models[selectedIdx] && (
                <div style={{ width: 800 }}>
                  <div style={{ float: "right" }}>
                    <Button onClick={runTest}>Run Test</Button>
                  </div>
                  <h2 style={{ marginTop: 15 }}>Testing</h2>
                  <div>
                    {models[selectedIdx] &&
                      "test_time" in models[selectedIdx] &&
                      "Test run time: " +
                      moment(models[selectedIdx].test_time)
                        .utc()
                        .format("MM/DD/YYYY HH:mm")}
                  </div>
                  <table style={{ width: 800 }}>
                    <thead>
                      <tr style={{ backgroundColor: "#eee" }}>
                        <th
                          style={{
                            width: "25%",
                            padding: 10,
                            textAlign: "left",
                          }}
                        >
                          Incorrect
                        </th>
                        <th
                          style={{
                            width: "25%",
                            padding: 10,
                            textAlign: "left",
                          }}
                        >
                          Correct
                        </th>
                        <th
                          style={{
                            width: "25%",
                            padding: 10,
                            textAlign: "left",
                          }}
                        >
                          Total
                        </th>
                        <th
                          style={{
                            width: "25%",
                            padding: 10,
                            textAlign: "left",
                          }}
                        >
                          Accuracy
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ padding: 10 }}>
                          {models[selectedIdx].test_incorrect.toLocaleString()}
                        </td>
                        <td style={{ padding: 10 }}>
                          {models[selectedIdx].test_correct.toLocaleString()}
                        </td>
                        <td style={{ padding: 10 }}>
                          {(
                            models[selectedIdx].test_incorrect +
                            models[selectedIdx].test_correct
                          ).toLocaleString()}
                        </td>
                        <td style={{ padding: 10 }}>
                          {(
                            (models[selectedIdx].test_correct /
                              (models[selectedIdx].test_correct +
                                models[selectedIdx].test_incorrect)) *
                            100
                          ).toFixed(4)}
                          {" % "}
                        </td>
                      </tr>
                    </tbody>
                  </table>

                  <table style={{ width: 800 }}>
                    <thead>
                      <tr style={{ backgroundColor: "#eee" }}>
                        <th
                          style={{
                            width: "50%",
                            padding: 10,
                            textAlign: "left",
                          }}
                        >
                          Incorrect (confidence &gt; 80%)
                        </th>
                        <th
                          style={{
                            width: "50%",
                            padding: 10,
                            textAlign: "left",
                          }}
                        >
                          Correct (confidence &lt; 80%)
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td style={{ padding: 10 }}>
                          {models[selectedIdx].confident_incorrect}
                        </td>
                        <td style={{ padding: 10 }}>
                          {models[selectedIdx].low_confidence_correct}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              )}
              {/* <h2>Production</h2> */}
              <Card title="Production">
                <div>
                  <Button onClick={getRealtime} loading={fetching}>
                    Get Real Time Stats
                  </Button>
                </div>
                {realtimeErrors && (
                  <div>
                    <table style={{ width: 800 }}>
                      <thead>
                        <tr style={{ backgroundColor: "#eee" }}>
                          <th
                            style={{
                              width: "25%",
                              padding: 10,
                              textAlign: "left",
                            }}
                          >
                            Incorrect
                          </th>
                          <th
                            style={{
                              width: "25%",
                              padding: 10,
                              textAlign: "left",
                            }}
                          >
                            Correct
                          </th>
                          <th
                            style={{
                              width: "25%",
                              padding: 10,
                              textAlign: "left",
                            }}
                          >
                            Total
                          </th>
                          <th
                            style={{
                              width: "25%",
                              padding: 10,
                              textAlign: "left",
                            }}
                          >
                            Accuracy
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ padding: 10 }}>
                            {realtimeErrors?.errorCount?.toLocaleString()}
                          </td>
                          <td style={{ padding: 10 }}>
                            {(
                              realtimeErrors?.totalCount -
                              realtimeErrors?.errorCount
                            ).toLocaleString()}
                          </td>
                          <td style={{ padding: 10 }}>
                            {realtimeErrors?.totalCount?.toLocaleString()}
                          </td>
                          <td style={{ padding: 10 }}>
                            {(
                              ((realtimeErrors?.totalCount -
                                realtimeErrors?.errorCount) /
                                realtimeErrors?.totalCount) *
                              100
                            ).toFixed(4)}
                            {" % "}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <table style={{ width: 800 }}>
                      <thead>
                        <tr style={{ backgroundColor: "#eee" }}>
                          <th
                            style={{
                              width: "50%",
                              padding: 10,
                              textAlign: "left",
                            }}
                          >
                            Incorrect (confidence &gt; 80%)
                          </th>
                          {/* <th style={{ width: "50%", padding: 10 }}>
                          Correct (confidence &lt; 80%)
                        </th> */}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td style={{ padding: 10 }}>
                            {realtimeErrors?.confidentError}
                          </td>
                          {/* <td style={{ padding: 10 }}>
                          {models[selectedIdx].low_confidence_correct}
                        </td> */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                )}
                <div style={{ marginTop: 10 }}>
                  <Button onClick={goToErrors}>Show Error Images</Button>
                </div>
                <div style={{ marginTop: 10 }}>
                  <Button onClick={goToLowConfidence}>
                    Show Low Confidence Images
                  </Button>
                </div>
              </Card>
              <br />
              <Collapse>
                <Panel header="Model Details" key="1">
                  <h2 style={{ marginTop: 15 }}>Preprocessing</h2>
                  <div>
                    Input shape:{" "}
                    {JSON.stringify(models[selectedIdx].input_shape)}
                  </div>
                  <div>
                    Color:{" "}
                    {models[selectedIdx].color === 1 ? "RGB" : "GrayScale"}
                  </div>
                  <div>Pixel Mean {models[selectedIdx].pixel_mean} </div>
                  <h2 style={{ marginTop: 15 }}>Architecture</h2>
                  <div>
                    {"config" in kerasModel &&
                      kerasModel.config.layers.map((layer, idx) => (
                        <div key={idx} style={{ marginTop: 10 }}>
                          {idx}{" "}
                          <span style={{ fontWeight: "bold" }}>
                            {layer.class_name}
                          </span>
                          {"filters" in layer.config && (
                            <span>Filters: {layer.config.filters}</span>
                          )}{" "}
                          {"kernel_size" in layer.config && (
                            <span>
                              kernel size:{" "}
                              {JSON.stringify(layer.config.kernel_size)}
                            </span>
                          )}{" "}
                          {"pool_size" in layer.config && (
                            <span>
                              pool size:{" "}
                              {JSON.stringify(layer.config.pool_size)}
                            </span>
                          )}{" "}
                          {"units" in layer.config && (
                            <span>
                              units: {JSON.stringify(layer.config.units)}
                            </span>
                          )}{" "}
                          {"activation" in layer.config && (
                            <span>
                              activation:{" "}
                              {JSON.stringify(layer.config.activation)}
                            </span>
                          )}{" "}
                          {"strides" in layer.config && (
                            <span>
                              strides: {JSON.stringify(layer.config.strides)}
                            </span>
                          )}{" "}
                          {"rate" in layer.config && (
                            <span>
                              rate: {JSON.stringify(layer.config.rate)}
                            </span>
                          )}
                          {/* <div>{JSON.stringify(layer.config)}</div> */}
                        </div>
                      ))}{" "}
                  </div>
                </Panel>
              </Collapse>
              {/* <div>Model {models[selectedIdx].keras_model} </div> */}
              {/* <div>{ models[selectedIdx].train_image_ids.map(id => <div>{ id } </div>) } </div> */}
              <Collapse style={{ marginTop: 15 }}>
                <Panel header="Different Images from previous model" key="1">
                  <h4>New Images</h4>
                  <ImageGrid
                    modelID={models[selectedIdx]._id}
                    imageIDs={newImages}
                  />
                  <h4>Removed Images</h4>
                  {/* <ImageGrid imageIDs={getRemovedImages()} /> */}
                  <div>
                    {" "}
                    {getRemovedImages().map((imageID, idx) => (
                      <div key={idx}>{imageID}</div>
                    ))}{" "}
                  </div>
                </Panel>
              </Collapse>
            </div>
          )}
        </div>
      </main>
      {models.length > 0 && (
        <DeleteModelModal
          showDeleteModal={showDeleteModal}
          closeDeleteModal={closeDeleteModal}
          modelID={models[selectedIdx]._id}
          reload={reload}
        />
      )}
    </div>
  );
}

export default inject("appState")(observer(ModelList));
