import React, { useState, useEffect } from "react";
import { Button, Modal } from "antd";
import { callApi } from "../libs/api";

const HeatMap = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [heatMapUrl, setHeatMapUrl] = useState("");

  useEffect(() => {
    const url = `/api/image/queryGradCam/${props.cameraID}/${props.modelID}/${props.imageID}`;
    callApi(url, "get")
      .then((data) => {
        setHeatMapUrl(data.url);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setHeatMapUrl("");
      });
  }, [showModal]);

  return (
    <div>
      <Button onClick={() => setShowModal(true)}>Heat Map</Button>
      <Modal
        title="Image Heat Map"
        width={860}
        visible={showModal}
        onOk={() => setShowModal(false)}
        onCancel={() => setShowModal(false)}
      >
        <img alt="" src={heatMapUrl} width={800} />
      </Modal>
    </div>
  );
};
export default HeatMap;
