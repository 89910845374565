import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Input, AutoComplete, Tag, message } from "antd";
import { callApi } from "../libs/api";
import getTagColor from "./getTagColor";
import { observer, inject } from "mobx-react";
import { CloseOutlined } from "@ant-design/icons";

function TagModal(props) {
  //tag labels selected
  const [tags, setTags] = useState([]);
  const [processing, setProcessing] = useState(false);
  //master list of tags
  const [masterTags, setMasterTags] = useState([]);
  const [tagBuffer, setTagBuffer] = useState("");

  const handleTagSelect = (tag) => {
    let newTags = [...tags, tag];
    setTags(newTags);
    setTagBuffer("");
  };

  const handleNewTagChange = (tag) => {
    setTagBuffer(tag);
  };

  const newTagEnter = (evt) => {
    if (tagBuffer === "") {
      message.error("no blank tags");
      return;
    }
    let newTags = [...tags, tagBuffer];
    setTags(newTags);
    setTagBuffer("");
  };

  const removeTag = (idx) => {
    const newTags = [...tags.slice(0, idx), ...tags.slice(idx + 1)];
    setTags(newTags);
  };

  const saveDialog = () => {
    //todo: replace with custom footer and disable save button if this is true
    if (tags.length === 0 && tagBuffer.length === 0) {
      message.error("No data to save.");
      return;
    }

    let localtags = tags;

    if (tagBuffer.length > 0) {
      localtags = [...tags, tagBuffer];
      console.log("new tags: " + JSON.stringify(localtags));
    }

    setProcessing(true);
    //post tags array to backend and the ids that go with selected_images
    const body = { selectedImageIds: props.selectedImageIds, tags: localtags };
    const url = "/api/" + props.api + "/multi";
    callApi(url, "put", body)
      .then((data) => {
        setProcessing(false);
        message.info(
          "Saved " +
            " tag" +
            (localtags.length > 1 ? "s" : "") +
            " to " +
            props.selectedImageIds.length +
            " image" +
            (props.selectedImageIds.length > 1 ? "s" : "")
        );
        setTags([]);
        setTagBuffer("");
        getTagMasterList();
        props.closeTagModal();
      })
      .catch((err) => {
        setProcessing(false);
        console.error(
          "Error: saveDialog -> callApi() -> url: " + url + " error: " + err
        );
        message.error("Error saving tags.");
      });
  };

  const cancelDialog = () => {
    setTagBuffer("");
    setTags([]);
    props.cancelTagModal();
  };

  useEffect(() => {
    if (props.showTagModal) {
      getTagMasterList();
    }
  }, [props.showTagModal]);

  const getTagMasterList = () => {
    //groundTruth or tag
    if (props.api.length === 0) {
      return;
    }
    const url = "/api/" + props.api + "/" + props.appState.cameraID;
    callApi(url, "get")
      .then((data) => {
        data.sort();
        if (props.api === "tag") {
          props.appState.setTagList(data);
        } else {
          props.appState.setGroundTruthList(data);
        }
        const tagData = data.map((itm) => {
          return { value: itm };
        });
        setMasterTags(tagData);
      })
      .catch((err) => {
        console.error(
          "Error: getTagMasterList 2 -> callApi() -> url: " +
            url +
            " error: " +
            err
        );
        setMasterTags([]);
      });
  };

  return (
    <Modal
      title="Image Tagging"
      visible={props.showTagModal}
      onOk={saveDialog}
      onCancel={cancelDialog}
      width={600}
      okText="Save and close"
      confirmLoading={processing}
    >
      <Row>
        <Col span={16}>
          <div className="myLabel">{props.title}</div>
          <div>
            <AutoComplete
              options={masterTags}
              allowClear={true}
              style={{ width: "100%" }}
              filterOption={(inputValue, option) => {
                return option.value
                  .toUpperCase()
                  .startsWith(inputValue.toUpperCase());
              }}
              onSelect={handleTagSelect}
              onChange={handleNewTagChange}
              value={tagBuffer}
            >
              <Input
                type="text"
                onPressEnter={newTagEnter}
                placeholder="Add tag and press enter"
              />
            </AutoComplete>
          </div>
          <div style={{ marginTop: 10, marginRight: 5 }}>
            {tags &&
              tags.map((tag, idx) => (
                <Tag key={tag} color={getTagColor(tag)}>
                  {tag} <CloseOutlined onClick={() => removeTag(idx)} />
                </Tag>
              ))}
          </div>
        </Col>
      </Row>
    </Modal>
  );
}

export default inject("appState")(observer(TagModal));
