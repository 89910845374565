const getTagColor = tag => {
  if (
    tag === "coil" ||
    tag === "lowered" ||
    tag === "sheet" ||
    tag === "dunnage" || 
    tag === "deer" || 
    tag === 'open'
  )
    return "green";
  if (
    tag === "no_coil" ||
    tag === "no_dunnage" ||
    tag === "no_sheet" ||
    tag === "not_lowered" ||
    tag === "no_deer" ||
    tag === 'closed'
  ) {
    return "red";
  }

  return "gray";
};

export default getTagColor;
