import {message} from 'antd'

const isValidPassword = (password, passwordConfirm) => {
  if (password !== passwordConfirm) {
    message.error("Passwords don't match.");
    return false;
  }

  if (password.length < 8) {
    message.error("Password must be at least 8 characters.");
    return false;
  }

  return true;
};

export default isValidPassword;
