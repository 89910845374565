import React, { useState, useEffect } from "react";

/* 
import PredictionLabel from './PredictionLabel'
<PredictionLabel image={img} model_id={this.state.model_id} /> */

function PredictionLabel(props) {
  const [prediction, setPrediction] = useState({
    prediction: "",
    confidence: 0
  });

  useEffect(() => {
    if (!props.model_id && props.image.prediction.length > 0) {
      setPrediction(props.image.prediction[0]);
      return;
    }
    const prediction = props.image.prediction.find(
      pre => pre.model_id === props.model_id
    );
    if (prediction === undefined) {
      setPrediction({ prediction: "", confidence: 0 });
      return;
    }
    setPrediction(prediction);
  }, [props.image, props.model_id]);

  return (
    <span>
      {props.image.prediction !== undefined &&
        props.image.prediction.length > 0 && (
          <span style={{ float: "right" }}>
            <span style={{ fontWeight: "bold", marginRight: 3 }}>
              {prediction.prediction}
            </span>
            {(prediction.confidence * 100).toFixed(2)}%
          </span>
        )}
    </span>
  );
}

export default PredictionLabel;
