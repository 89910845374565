import React, { useState, useEffect } from "react";
import { inject } from "mobx-react";
import { Button, Input, message } from "antd";
import { useHistory, useParams } from "react-router-dom";
import fetch from "isomorphic-fetch";
import { LockOutlined } from "@ant-design/icons";
import isValidPassword from "./isValidPassword";

const ResetPassword = (props) => {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [loading, setLoading] = useState(false);
  let history = useHistory();

  let { token } = useParams();

  useEffect(() => {
    if (props.appState.loggedIn) {
      history.push("/image");
    }
  }, []);

  const changePassword = () => {
    if (!isValidPassword(password, passwordConfirm)) return;

    setLoading(true);
    const headers = {
      "content-type": "application/json",
      Authorization: "Bearer " + token,
    };
    //todo: production / dev url
    const url = props.appState.imageHost + "/api/users/changePassword/";
    const body = { password };
    console.log(JSON.stringify(headers));
    fetch(url, {
      headers: headers,
      method: "PUT",
      body: JSON.stringify(body),
    })
      .then(async (response) => {
        const data = await response.json();
        setLoading(false);
        if (!response.ok) {
          console.error(
            "Error: callApi() -> url: " +
              url +
              " error: " +
              JSON.stringify(response)
          );
          message.error("Change Password Error.");
        } else {
          message.info("Password changed!!!");

          sessionStorage.setItem("access_token", data.token);
          sessionStorage.setItem("userName", data.userName);
          sessionStorage.setItem("userAdmin", data.profile.admin);

          props.appState.userName = data.userName;
          props.appState.loggedIn = true;
          props.appState.setUserAdmin(data.profile.admin);
          history.push("/image");
          //history.push("/")
        }
      })
      .catch((err) => {
        console.error(
          "Error: callApi() -> url: " + url + " error: " + JSON.stringify(err)
        );
        message.error("Change Password Error.");
        setLoading(false);
      });
  };

  const handlePassword = (event) => {
    setPassword(event.target.value);
  };

  const handlePasswordConfirmation = (event) => {
    setPasswordConfirm(event.target.value);
  };

  return (
    <div className="mybg">
      <div className="loginContainer">
        <h3>Change Password</h3>
        <div>
          New Password{" "}
          <Input.Password
            value={password}
            onChange={handlePassword}
            placeholder="Password"
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          />
        </div>
        <div>
          Confirm Password{" "}
          <Input.Password
            value={passwordConfirm}
            onChange={handlePasswordConfirmation}
            placeholder="Password Confirmation"
            prefix={<LockOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          />
        </div>
        <div style={{ marginTop: 10 }}>
          <Button
            style={{ width: "100%" }}
            loading={loading}
            type="primary"
            onClick={changePassword}
          >
            Change Password
          </Button>
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default inject("appState")(ResetPassword);
