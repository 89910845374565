import React, { useEffect, useRef } from "react";
import { observer, inject } from "mobx-react";
import { Select } from "antd";
import moment from "moment";

function ModelSelect(props) {
  const isInitialMount = useRef(true);


  useEffect(() => {
    //if mount
    if (isInitialMount.current) {
      isInitialMount.current = false;
    } else {
      props.appState.setModelID("");
    }
  }, [props.appState.cameraID]);

  const models = props.appState.models;
  const loading = props.appState.loadingModels;
  const error = props.appState.modelError;

  const handleModelChange = (val) => {
    props.appState.setModelID(val);
  };

  return (
    <div>
      {error && "Error"}

      <Select
        value={props.appState.modelID}
        onChange={handleModelChange}
        style={{ width: "100%" }}
        allowClear={true}
        loading={loading}
      >
        <Select.Option value="">All </Select.Option>
        {models.map((model) => {
          return (
            <Select.Option value={model._id} key={model._id}>
              {new moment(model.run_time).utc().format("MM/DD HH:mm")}{" "}
              {model.published && " Published"}
            </Select.Option>
          );
        })}
      </Select>
    </div>
  );
}

export default inject("appState")(observer(ModelSelect));
