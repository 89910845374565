import React, { useState, useEffect } from "react";
import { observer, inject } from "mobx-react";
import { callApi } from "../libs/api";
import {
  Row,
  Col,
  Pagination,
  Alert,
  Spin,
  Button,
  Radio,
  Checkbox,
  Tag,
  DatePicker,
} from "antd";
import moment from "moment";
import CameraSelect from "./CameraSelect";
import UserSelect from "./UserSelect";
import ModelSelect from "./ModelSelect";
import PredictionSelect from "./PredictionSelect";
import GroundTruthSelect from "./GroundTruthSelect";
import TagSelect from "./TagSelect";
import ImageModal from "./ImageModal";
import TagModal from "./TagModal";
import ImageDateTime from "./ImageDateTime";
import DeleteImageModal from "./DeleteImageModal";
import getTagColor from "./getTagColor";
import PredictionLabel from "./PredictionLabel";
import PredictionBar from "./PredictionBar";
import AddImageButton from "./AddImageButton";
import { DeleteOutlined, TagOutlined, TagsOutlined } from "@ant-design/icons";
import TagManager from "./TagManager";
import GroundTruthManager from "./GroundTruthManager";
import NoImagePanel from "./NoImagePanel";

const Image = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [imageCount, setImageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectAll, setSelectAll] = useState("Select All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageIds, setSelectedImageIds] = useState([]);
  const [tagModalType, setTagModalType] = useState("");

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showImageDialog, setShowImageDialog] = useState(false);
  const [showTagModal, setShowTagModal] = useState(false);
  const [showTagManager, setShowTagManager] = useState(false);
  const [groundTruthManagerVisible, setGroundTruthManagerVisible] =
    useState(false);
  const [tagModalTitle, setTagModalTitle] = useState(false);
  const [isShiftDown, setIsShiftDown] = useState(false);

  useEffect(() => {
    console.log("Image > useEffect");
    queryImages();
  }, [currentPage, props.appState.imageSort]);

  const reload = () => {
    setCurrentPage(1);
    queryImages();
  };

  const clearSearch = () => {
    props.appState.setModelID("");
    props.appState.setGroundTruth("");
    props.appState.setTag("");
    props.appState.setUserID("");
    setStartDate("");
    setEndDate("");
    props.appState.setImageSort("newest");
    setCurrentPage(1);
    props.appState.setShowModelErrors(false);
    props.appState.setShowHeatMap(false);

    //this is a race condition
    //needs to trigger after all setters are finished?
    //queryImages();
  };

  const handleSort = (event) => {
    props.appState.setImageSort(event.target.value);
  };

  const changePage = (page) => {
    setCurrentPage(page);
  };

  const queryImages = () => {
    if (!props.appState.cameraID) {
      return;
    }

    if (props.appState.modelID && props.appState.showModelErrors) {
      queryModelErrors();
      return;
    }
    setLoading(true);
    setError(false);
    props.appState.setImageList([]);
    const url = "/api/image/query";
    const body = buildQueryBody();

    callApi(url, "POST", body)
      .then((data) => {
        setLoading(false);
        setError(false);
        setImageCount(data.count);
        props.appState.setImageList(data.list);
        setSelectedImages(new Array(data.count));
        setSelectedImageIds([]);
        setSelectAll("Select All");
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoading(false);
        setError(true);
        props.appState.setImageList([]);
      });
  };

  const buildQueryBody = () => {
    const body = {
      query: { camera_id: props.appState.cameraID },
      sort: props.appState.imageSort,
      skip: (currentPage - 1) * props.appState.pageSize,
      pageSize: props.appState.pageSize,
    };

    if (startDate) {
      body.query.start_date = startDate.format("YYYYMMDD/HHmmss");
    }
    if (endDate) {
      body.query.end_date = endDate.format("YYYYMMDD/HHmmss");
    }
    if (props.appState.tag?.length > 0) {
      body.query.search_tag = props.appState.tag;
    }
    if (props.appState.groundTruth?.length > 0) {
      body.query.ground_truth = props.appState.groundTruth;
    }
    if (
      props.appState.modelID &&
      (props.appState.modelID.length > 0 || props.appState.modelID > 0)
    ) {
      body.query.model_id = props.appState.modelID;
      if (props.appState.predictionFilter?.length > 0) {
        body.query.predictionFilter = props.appState.predictionFilter;
      }
    }
    if (props.appState.userID && props.appState.userID?.length > 0) {
      body.query.lastUpdateUser = props.appState.userID;
    }
    if (props.appState.showTrainingImages) {
      body.query.showTrainingImages = 1;
    }
    return body;
  };

  const queryModelErrors = () => {
    setLoading(true);
    setError(false);
    props.appState.setImageList([]);
    const url = "/api/image/queryModelErrors";
    const body = buildQueryBody();
    callApi(url, "POST", body)
      .then((data) => {
        setLoading(false);
        setError(false);
        setImageCount(data.count);
        props.appState.setImageList(data.list);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setLoading(false);
        setError(true);
        props.appState.setImageList([]);
      });
  };

  const selectTagPredicted = (selectTag) => {
    let selected_image_ids = [];
    let selected_images = new Array(props.appState.imageList.length);
    for (let i = 0; i < props.appState.imageList.length; i++) {
      if (props.appState.imageList[i].prediction) {
        const prediction = props.appState.imageList[i].prediction.find(
          (pred) => {
            if (props.appState.modelID === pred.model_id) {
              return true;
            }
            return false;
          }
        );
        if (prediction && selectTag === prediction.prediction) {
          selected_images[i] = true;
          selected_image_ids.push(props.appState.imageList[i]._id);
        }
      }
    }
    setSelectedImages(selected_images);
    setSelectedImageIds(selected_image_ids);
  };

  const toggleSelectAll = () => {
    let selected_image_ids = [];
    if (selectAll === "Select All") {
      let selected_images = new Array(props.appState.imageList.length);
      for (let i = 0; i < props.appState.imageList.length; i++) {
        selected_images[i] = true;
        selected_image_ids.push(props.appState.imageList[i]._id);
      }
      setSelectAll("Unselect All");
      setSelectedImages(selected_images);
      setSelectedImageIds(selected_image_ids);
    } else {
      const selected_images = new Array(imageCount);
      setSelectAll("Select All");
      setSelectedImages(selected_images);
      setSelectedImageIds(selected_image_ids);
    }
  };

  const selectLeft = (idx) => {
    console.log("selectLeft " + idx);
    let selected_images_copy = [...selectedImages];
    let selected_image_ids_copy = [...selectedImageIds];
    selected_images_copy[idx] = false;
    for (let i = idx; i >= 0; i--) {
      console.log("i " + i);

      if (selected_images_copy[i]) {
        break;
      }
      selected_images_copy[i] = true;
      selected_image_ids_copy.push(props.appState.imageList[i]._id);
    }
    setSelectedImages(selected_images_copy);
    setSelectedImageIds(selected_image_ids_copy);
  };

  useEffect(() => {
    document.addEventListener("keyup", handleKeyUp, false);
    document.addEventListener("keydown", handleKeyDown, false);
    return () => {
      document.removeEventListener("keyup", handleKeyUp);
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);

  const handleKeyUp = (e) => {
    if (e.key === "Shift") {
      setIsShiftDown(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Shift") {
      setIsShiftDown(true);
    }
  };

  const checkImage = (evt, idx) => {
    if (isShiftDown) {
      console.log("shift key");
      //evt.stopPropagation();
      selectLeft(idx);
      return;
    }
    let selected_images_copy = [...selectedImages];
    selected_images_copy[idx] = !selected_images_copy[idx];
    setSelectedImages(selected_images_copy);

    let selected_image_ids_copy = [...selectedImageIds];
    if (selected_images_copy[idx]) {
      //if checked, add it.
      selected_image_ids_copy.push(props.appState.imageList[idx]._id);
    } else {
      //delete
      const idToDelete = props.appState.imageList[idx]._id;
      selected_image_ids_copy = selected_image_ids_copy.filter(function (
        value
      ) {
        return value !== idToDelete;
      });
    }
    setSelectedImageIds(selected_image_ids_copy);
  };

  const goToImage = (offset) => {
    const nextIndex = props.appState.imageIdx + offset;
    if (nextIndex < 0) {
      return;
    } else if (nextIndex + 1 > props.appState.imageList.length) {
      return;
    }
    props.appState.setImageIdx(nextIndex);
  };

  const closeImageDialog = () => {
    setShowImageDialog(false);
  };

  const cancelTagModal = () => {
    setShowTagModal(false);
  };

  const closeTagModal = () => {
    setShowTagModal(false);
    queryImages();
  };

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const canUserEdit = (img) => {
    if (
      props.appState.isUserAdmin() ||
      img.lastUpdateUser === props.appState.userName ||
      img.lastUpdateUser === undefined
    ) {
      return true;
    }
    return false;
  };

  const setTagManagerVisible = () => {
    setShowTagManager(true);
  };

  return (
    <div className="subPage">
      <nav className="sidemenu">
        <div className="sideNavItems">
          <div className="myLabelTop">Cameras</div>
          <CameraSelect reload={reload} />
        </div>
        {props.appState.cameraID && (
          <div>
            <div className="sideNavItems">
              <div className="myLabel">
                Ground Truth
                {props.appState.userAdmin &&
                  props.appState.groundTruthList.length > 0 && (
                    <Button
                      style={{ float: "right", marginBottom: 3 }}
                      icon={<TagsOutlined />}
                      onClick={setGroundTruthManagerVisible}
                    ></Button>
                  )}
              </div>
              <GroundTruthSelect />
            </div>
            <div className="sideNavItems">
              <div className="myLabel">
                Tags
                {props.appState.userAdmin &&
                  props.appState.tagList.length > 0 && (
                    <Button
                      style={{ float: "right", marginBottom: 3 }}
                      icon={<TagsOutlined />}
                      onClick={setTagManagerVisible}
                    ></Button>
                  )}
              </div>
              <TagSelect />
            </div>
            {props.appState.models.length > 0 && (
              <div>
                <div className="sideNavItems">
                  <div className="myLabel">
                    <span>Models</span>
                  </div>
                  <ModelSelect />
                </div>
                <div className="sideNavItems">
                  <div>
                    <Checkbox
                      disabled={!props.appState.modelID}
                      checked={props.appState.showModelErrors}
                      onChange={() =>
                        props.appState.setShowModelErrors(
                          !props.appState.showModelErrors
                        )
                      }
                    >
                      Only Show Errors{" "}
                    </Checkbox>
                  </div>

                  <Checkbox
                    disabled={!props.appState.modelID}
                    checked={props.appState.showTrainingImages}
                    onChange={() =>
                      props.appState.setShowTrainingImages(
                        !props.appState.showTrainingImages
                      )
                    }
                  >
                    Show Training Images{" "}
                  </Checkbox>
                  <div>
                  <Checkbox
                    disabled={!props.appState.modelID}
                    checked={props.appState.showHeatMap}
                    onChange={() =>
                      props.appState.setShowHeatMap(
                        !props.appState.showHeatMap
                      )
                    }
                  >
                    Show Heat Map{" "}
                  </Checkbox>
                  </div>
                  <div className="myLabel">
                    <span className={props.appState.modelID ? "" : "disabled"}>
                      Predictions
                    </span>
                  </div>
                  <PredictionSelect disabled={!props.appState.modelID} />
                </div>
              </div>
            )}

            {props.appState.isUserAdmin() && (
              <div className="sideNavItems">
                <div className="myLabelTop">Last User Tagged</div>
                <UserSelect reload={reload} />
              </div>
            )}
            <div className="sideNavItems">
              <div className="myLabel">Earliest Date</div>
              <DatePicker
                value={startDate}
                onChange={(dt, dtStr) => setStartDate(dt)}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              />
            </div>
            <div className="sideNavItems">
              <div className="myLabel">Latest Date</div>
              <DatePicker
                value={endDate}
                onChange={(dt, dtStr) => setEndDate(dt)}
                format="YYYY-MM-DD HH:mm:ss"
                showTime={{ defaultValue: moment("00:00:00", "HH:mm:ss") }}
              />
            </div>
            <div className="sideNavItems">
              <div className="myLabel">Sort</div>
              <div>
                <Radio
                  value="newest"
                  checked={props.appState.imageSort === "newest"}
                  onChange={handleSort}
                >
                  Newest
                </Radio>
              </div>
              <div>
                <Radio
                  value="oldest"
                  checked={props.appState.imageSort === "oldest"}
                  onChange={handleSort}
                >
                  Oldest
                </Radio>
              </div>
              <div>
                {props.appState.models.length > 0 && (
                  <div>
                    <Radio
                      value="high_confidence"
                      checked={props.appState.imageSort === "high_confidence"}
                      onChange={handleSort}
                    >
                      High confidence
                    </Radio>
                    <Radio
                      value="low_confidence"
                      checked={props.appState.imageSort === "low_confidence"}
                      onChange={handleSort}
                    >
                      Low confidence
                    </Radio>
                  </div>
                )}
              </div>

              <div>
                <Radio
                  value="tagged"
                  checked={props.appState.imageSort === "tagged"}
                  onChange={handleSort}
                >
                  Recently tagged
                </Radio>
              </div>
            </div>

            <div className="paddingThirtyTop">
              <Button
                type="primary"
                size="large"
                loading={loading}
                onClick={reload}
              >
                Reload
              </Button>
              <Button
                size="large"
                onClick={clearSearch}
                style={{ marginLeft: 8 }}
              >
                Clear
              </Button>
            </div>
            <div className="sideNavItems">
              Images: {imageCount && imageCount.toLocaleString()}
            </div>

            {props.appState.models.length > 0 && (
              <div>
                <div className="sideNavItems">
                  <div className="myLabel">Select Predictions</div>
                  <div>(you must select a model)</div>
                </div>
                {props.appState.groundTruthList.map((truth, idx) => (
                  <div
                    key={idx}
                    className="sideNavItems"
                    onClick={() => {
                      selectTagPredicted(truth);
                    }}
                  >
                    <span className={props.appState.modelID ? "" : "disabled"}>
                      <TagOutlined className="subButtons" />
                      Select {truth}
                    </span>
                  </div>
                ))}

                <div className="paddingThirtyTop" style={{ marginBottom: 20 }}>
                  {/* <Button
                    type="primary"
                    size="large"
                    loading={loading}
                    onClick={queryModelErrors}
                  >
                    Show Model Errors
                  </Button> */}
                </div>
              </div>
            )}
          </div>
        )}
      </nav>
      <main className="main">
        <Row>
          <Col md={24} lg={14}>
            <div className="subMenu">
              {props.appState.isUserAdmin() && (
                <AddImageButton reload={reload} menu={true} />
              )}
              {props.appState.isUserAdmin() && (
                <div
                  className={
                    selectedImageIds.length !== 0
                      ? "subMenuItem"
                      : "disabledSubMenuItem"
                  }
                  onClick={() => {
                    if (selectedImageIds.length === 0) {
                      return;
                    }
                    setShowDeleteModal(true);
                  }}
                >
                  <DeleteOutlined className="subButtons" />
                  Delete
                </div>
              )}
              <div
                className={
                  selectedImageIds.length !== 0
                    ? "subMenuItem"
                    : "disabledSubMenuItem"
                }
                onClick={() => {
                  if (selectedImageIds.length === 0) {
                    return;
                  }
                  setShowTagModal(true);
                  setTagModalType("groundTruth");
                  setTagModalTitle("Ground Truth Tags");
                }}
              >
                <TagOutlined className="subButtons" />
                Truth
              </div>
              <div
                className={
                  selectedImageIds.length !== 0
                    ? "subMenuItem"
                    : "disabledSubMenuItem"
                }
                onClick={() => {
                  if (selectedImageIds.length === 0) {
                    return;
                  }
                  setShowTagModal(true);
                  setTagModalType("tag");
                  setTagModalTitle("My Tags");
                }}
              >
                <TagOutlined className="subButtons" />
                Tag
              </div>
              <div className="subMenuItem" onClick={toggleSelectAll}>
                {selectAll}
              </div>
            </div>
          </Col>
          <Col md={24} lg={10}>
            <div style={{ float: "right", marginRight: 30, marginTop: 15 }}>
              {!loading && imageCount > 0 && (
                <Pagination
                  defaultCurrent={1}
                  current={currentPage}
                  pageSize={props.appState.pageSize}
                  total={imageCount}
                  onChange={changePage}
                  showSizeChanger={false}
                />
              )}
            </div>
          </Col>
        </Row>
        <div style={{ textAlign: "center" }}>
          <div>{loading && <Spin size="large" />}</div>
          <div>
            {error && <Alert message="Error loading data" type="error" />}
          </div>
        </div>
        {props.appState.imageList.length === 0 &&
          !loading &&
          !error &&
          props.appState.cameraID && <NoImagePanel reload={reload} />}
        <div style={{ display: "flex", flexWrap: "wrap", margin: 20 }}>
          {props.appState.imageList.map((img, idx) => (
            <div
              style={{
                flexGrow: 1,
                width: 290,
                marginRight: 3,
                marginBottom: 18,
              }}
              key={img._id}
            >
              <div>
                <a
                  onClick={() => {
                    setShowImageDialog(true);
                    props.appState.setImageIdx(idx);
                  }}
                >
                  <img
                    alt={img._id}
                    style={{ width: "100%" }}
                    src={props.appState.showHeatMap ? img.heatmapUrl : img.thumbImageUrl}
                  />
                </a>
                {img?.prediction?.length > 0 && (
                  <PredictionBar
                    image={img}
                    model_id={props.appState.modelID}
                  />
                )}
              </div>
              <div className="imageSubLine">
                {canUserEdit(img) && (
                  <span>
                    <Checkbox
                      checked={selectedImages[idx]}
                      onChange={(evt) => checkImage(evt, idx)}
                      style={{ marginRight: 3 }}
                    >
                      <ImageDateTime image={img} />
                    </Checkbox>
                  </span>
                )}
                {!canUserEdit(img) && <ImageDateTime image={img} />}
                <PredictionLabel
                  image={img}
                  model_id={props.appState.modelID}
                />
                <div>
                  {img?.tags?.length > 0 && (
                    <div
                      style={
                        img?.ground_truth?.length > 0 ? { float: "right" } : {}
                      }
                    >
                      <span style={{ fontWeight: "bold" }}></span>{" "}
                      {img.tags &&
                        img.tags.map((tag, idx) => (
                          <Tag
                            key={idx}
                            color={getTagColor(tag)}
                            style={{ marginBottom: 5 }}
                          >
                            {tag}
                          </Tag>
                        ))}
                    </div>
                  )}
                  {img?.ground_truth?.length > 0 && (
                    <div>
                      <span style={{ fontWeight: "bold" }}></span>{" "}
                      {img.ground_truth &&
                        img.ground_truth.map((tag, idx) => (
                          <Tag
                            key={idx}
                            color={getTagColor(tag)}
                            style={{ marginBottom: 5 }}
                          >
                            {tag}
                          </Tag>
                        ))}
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
        <div style={{ marginBottom: 20 }}>
          {!loading && (
            <div style={{ float: "right", marginRight: 30, marginBottom: 40 }}>
              {imageCount > 0 && (
                <Pagination
                  defaultCurrent={1}
                  current={currentPage}
                  pageSize={props.appState.pageSize}
                  total={imageCount}
                  onChange={changePage}
                  showSizeChanger={false}
                />
              )}
            </div>
          )}
        </div>
      </main>
      <ImageModal
        showDialog={showImageDialog}
        close={closeImageDialog}
        goToImage={goToImage}
      />
      <TagModal
        showTagModal={showTagModal}
        closeTagModal={closeTagModal}
        cancelTagModal={cancelTagModal}
        selectedImageIds={selectedImageIds}
        api={tagModalType}
        title={tagModalTitle}
      />
      <DeleteImageModal
        showDeleteModal={showDeleteModal}
        closeDeleteModal={closeDeleteModal}
        selectedImageIds={selectedImageIds}
        reload={queryImages}
      />
      <TagManager
        visible={showTagManager}
        close={() => setShowTagManager(false)}
        reload={queryImages}
      />
      <GroundTruthManager
        visible={groundTruthManagerVisible}
        close={() => setGroundTruthManagerVisible(false)}
        reload={queryImages}
      />
    </div>
  );
};

export default inject("appState")(observer(Image));
