import React, { useState, useEffect } from "react";
import { Modal, Input, message } from "antd";
import { callApi } from "../libs/api";

function CameraModal(props) {
  const [cameraLoading, setCameraLoading] = useState(false);
  const [cameraError, setCameraError] = useState(false);
  const [camera, setCamera] = useState({ name: "", ipAddress: "" });

  const handleNameChange = (evt) => {
    let c = { ...camera };
    c.name = evt.target.value;
    setCamera(c);
  };

  const handleIPChange = (evt) => {
    let c = { ...camera };
    c.ipAddress = evt.target.value;
    setCamera(c);
  };


  useEffect(() => {
    if (props.cameraID === 0) {
      setCamera({ name: "" });
      return;
    }
    getCamera(props.cameraID);
  }, [props.cameraID]);

  const getCamera = (id) => {
    setCameraLoading(true);
    setCameraError(false);
    const url = "/api/camera/" + id;
    callApi(url, "GET")
      .then((data) => {
        setCameraLoading(false);
        setCamera(data);
      })
      .catch((err) => {
        console.error("Error: callApi() -> url: " + url + " error: " + err);
        setCameraLoading(false);
        setCameraError(true);
      });
  };

  const save = async () => {
    await callApi("/api/camera/", "put", camera)
      .then((data) => {
        message.info("Camera saved");
        props.close();
        setCamera({ name: "", ipAddress: "" })
      })
      .catch((err) => {
        console.error("error saving camera " + JSON.stringify(err));
        message.error("Error saving camera");
      });
  };

  return (
    <Modal
      title="Camera Detail"
      visible={props.showDialog}
      onOk={save}
      onCancel={props.close}
      width={600}
      style={{ top: 20 }}
    >
      {cameraLoading && "Loading..."}
      {cameraError && "Error Loading..."}
      <div>
        Name{" "}
        <Input
          value={camera.name}
          type="text"
          placeholder="Camera Name"
          onChange={handleNameChange}
        />
      </div>
      <div>
        IP address{" "}
        <Input
          value={camera.ipAddress}
          type="text"
          placeholder="IP address"
          onChange={handleIPChange}
        />
      </div>
    </Modal>
  );
}

export default CameraModal;
