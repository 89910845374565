import { observable, action, runInAction } from "mobx";
import { callApi } from "../libs/api";

var appState = observable(
  {
    userName: "",
    customerID: "",
    loggedIn: false,
    setLoginInfo(userName, customerID, admin){
      this.userName = userName;
      this.customerID = customerID
      this.userAdmin = parseInt(admin)
      this.loggedIn = true
    },
    isLoggedIn() {
      if (this.loggedIn) return true;

      let accessToken = localStorage.getItem("access_token");
      if (accessToken?.length > 0) {
        this.loggedIn = true;
        this.userName = localStorage.getItem("userName");
        this.setUserAdmin(localStorage.getItem("userAdmin"));
        this.customerID = localStorage.getItem("customerID");
        return true;
      }

      accessToken = sessionStorage.getItem("access_token");
      if (accessToken?.length > 0) {
        this.loggedIn = true;
        this.userName = sessionStorage.getItem("userName");
        this.setUserAdmin(sessionStorage.getItem("userAdmin"));
        this.customerID = sessionStorage.getItem("customerID");
        return true;
      }

      return false;
    },
    userAdmin: 0,
    isUserAdmin() {
      //sometimes the 1 is a string?
      if (this.userAdmin === 1) return true;
      this.isLoggedIn();
      return this.userAdmin === 1;
    },
    setUserAdmin(val) {
      this.userAdmin = parseInt(val);
    },
    rememberMe: false,


    imageHost: process.env.REACT_APP_API_URL,
    //imageHost:'https://api.observation.ai',
    //imageHost: "http://localhost:4001",
    pageSize: 50,
    cameraID: "",
    setCameraID(val) {
      this.cameraID = val;
    },
    userID: "",
    setUserID(val) {
      this.userID = val;
    },
    modelID: "",
    setModelID(val) {
      this.modelID = val;
    },
    groundTruth: "",
    setGroundTruth(val) {
      this.groundTruth = val;
    },
    predictionFilter: "",
    setPredictionFilter(val) {
      this.predictionFilter = val;
    },
    imageSort: "newest",
    setImageSort(val) {
      this.imageSort = val;
    },
    groundTruthList: [],
    setGroundTruthList(val) {
      this.groundTruthList = val;
    },
    tag: "",
    setTag(val) {
      this.tag = val;
    },
    tagList: [],
    setTagList(val) {
      this.tagList = val;
    },
    showModelErrors: false,
    setShowModelErrors(val) {
      this.showModelErrors = val;
    },
    showTrainingImages: false,
    setShowTrainingImages(val){
      this.showTrainingImages = val
    },
    showHeatMap: false,
    setShowHeatMap(val){
      this.showHeatMap = val
    },
    imageList: [],
    setImageList(val) {
      this.imageList = val;
    },
    imageIdx: 0,
    setImageIdx(val) {
      this.imageIdx = val;
    },
    setImage(img) {
      let newList = [...this.imageList];
      newList[this.imageIdx] = img;
      this.setImageList(newList);
    },
    setImageTags(idx, tagType, tags) {
      this.imageList[idx][tagType] = tags;
    },
    reset() {
      this.userName = "";
      this.loggedIn = false;
      this.cameraID = "";
      this.modelID = "";
      this.tag = "";
      this.tagList = [];
      this.groundTruth = "";
      this.groundTruthList = [];

      this.imageIdx = 0;
      this.imageList = [];
      this.models = [];
    },
    models: [],
    loadingModels: false,
    modelError: false,
    async fetchModels() {
      return new Promise(async (resolve, reject) => {
        this.loadingModels = true;
        this.modelError = false;
        const url = "/api/model/query/" + this.cameraID;

        try {
          const data = await callApi(url, "GET");
          runInAction(() => {
            this.models = data;
            this.loadingModels = false;
            resolve();
          });
        } catch (err) {
          console.error(err);
          runInAction(() => {
            this.modelError = true;
            this.loadingModels = false;
            reject(err);
          });
        }
      });
    },
  },
  {
    setLoginInfo: action,
    setUserAdmin: action,
    setCameraID: action,
    setUserID: action,
    setModelID: action,
    setGroundTruth: action,
    setImageSort: action,
    setGroundTruthList: action,
    setTag: action,
    setTagList: action,
    setShowModelErrors: action,
    setShowTrainingImages: action,
    setShowHeatMap: action,
    setImageList: action,
    setImageIdx: action,
    setImageTags: action,
    reset: action,
    fetchModels: action,
  }
);

export default appState;
