import React from "react";
import { Progress } from "antd";
import { getTagPredictionColor2 } from "./getTagPredictionColor";
import HeatMap from "./HeatMap";

const Predictions = (props) => {
  return (
    <div>
      {props.prediction &&
        props.prediction.map((prediction, idx) => (
          <div key={idx} style={{ margin: 3 }}>
            <Progress
              percent={parseInt(prediction.confidence * 100)}
              status={getTagPredictionColor2(prediction.prediction)}
              showInfo={true}
            />
            <span style={{ fontWeight: "bold" }}>{prediction.prediction}</span>:{" "}
            {(prediction.confidence * 100).toFixed(2)}% Model{" "}
            {prediction.model_id}
            <div>
              { <HeatMap
                cameraID={props.cameraID}
                modelID={prediction.model_id}
                imageID={props.imageID}
              /> }
            </div>
          </div>
        ))}
    </div>
  );
};
export default Predictions;
