import React from "react";
import { inject } from "mobx-react";

function ImageDateTime(props) {
  const img = props.image;
  //1st case: orginal file name is for datasets.
  //2nd case: cameras with no original file names. use date time for file name.
  //todo: case 2 needs to convert to timezone
  //3rd case: jemison use file name for date time? I don't have customer id so I use userName.
  return (
    <span>
      {img?.original_file_name && <span>{img.original_file_name}</span>}
      {!img?.original_file_name &&
        img?.capture_time &&
        props.appState.customerID !== "5da4bdeb0eb8d5be306a85ac" && (
          <span>{img.capture_time}</span>
        )}
      {!img?.original_file_name &&
        img?.file_name?.length > 10 &&
        props.appState.customerID === "5da4bdeb0eb8d5be306a85ac" && (
          <span>
            <span>
              {img.file_name.substring(4, 6)}/{img.file_name.substring(6, 8)}/
              {img.file_name.substring(0, 4)}
              <span style={{ marginLeft: 3 }}>
                {img.file_name.substring(9, 11)}:
                {img.file_name.substring(12, 14)}:
                {img.file_name.substring(15, 17)}
              </span>
            </span>
          </span>
        )}
    </span>
  );
}

export default inject("appState")(ImageDateTime);
